@import "./variables";
@import "./icon.mixin";

.wrapper {
  margin: 0;
  height: 32px;
}
.icon {
  @include icon-style-primary;
  margin: 0 $spacing-t9;
  padding: 0 $spacing-t9 !important;
  min-height: 24px;
  min-width: 24px;
}

.tooltipContainer {
  margin: 0 !important;
}
