@import "../variables";

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 99;
}

.actionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    margin-left: $spacing-t7;
  }
}

.loadingContainer {
  > div:first-child {
    margin-bottom: $spacing-t3;
  }

  .content {
    margin: 0 $spacing-t5;
  }
}
