@import "../variables";
@import "../button.mixin.scss";

.name {
  word-break: break-all;
  white-space: normal;
}

.iconButton {
  padding: 0;
  min-width: 32px !important;
  min-height: 32px !important;
  margin-left: $spacing-t8;
}
.crossButton {
  padding: 0;
  min-width: 32px !important;
  min-height: 32px !important;
  margin-left: $spacing-t8;
  :global(.fill-color) {
    fill: $color-icon-error !important;
  }
}

.mapButtonWrapper {
  display: flex;
  align-items: flex-start;
  margin: -0.5rem 0rem;
}

.headerButton {
  margin-right: $spacing-t6;
}
.disabledButton {
  opacity: 0.8;
  cursor: not-allowed;
  :global(.fill-color) {
    fill: $color-icon-disabled;
  }
}

.groupWrapper {
  padding: 0 $spacing-t8;
  margin-bottom: $spacing-t6 !important;
  font-size: $font-size-t5;
  p {
    font-size: $font-size-t4;
  }
}
.groupTitle {
  font-weight: $font-weight-semi-bold;
  margin-bottom: $spacing-t9;
  display: block;
}
