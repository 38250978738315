@import "../variables";
@import "../button.mixin";

.container {
  margin: $spacing-t4 0 $spacing-t4 0;
  .actions {
    margin-right: $spacing-t6;

    .cancelButton {
      @include cancelButton;
      padding: 0 $spacing-t6 !important;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
    }
    .saveButton {
      @include actionButton($color-button-bg-green);
      padding: 0 $spacing-t6 !important;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      :global(.fill-color) {
        color: $color-icon-white;
        fill: $color-icon-white;
      }

      :global(.#{$ns}-button-text) {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    .editButton {
      @include buttonStyle;
      background-color: $color-button-bg-default !important;
      color: $color-text-primary !important;
      padding: 0 $spacing-t7 !important;
      font-size: $font-size-t4;
      line-height: $line-height-t4;
      :global(.fill-color) {
        color: $color-icon-primary !important;
        fill: $color-icon-primary !important;
      }
      &:hover {
        background-color: rgba($color-button-bg-default, 0.75) !important;
      }
    }
  }

  .countries {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    .title {
      color: $color-text-default;
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t5;
      margin: $spacing-t8 0;
    }

    .list {
      color: $color-text-default;
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t5;
      margin-left: $spacing-t8;
    }
  }

  .criteriaWrapper {
    display: flex;
    justify-items: flex-start;
    margin-top: $spacing-t7;
    width: 100%;

    .criteriaItem {
      display: flex;
      justify-items: flex-start;
      flex-direction: column;
      flex: 2;

      &:first-child {
        margin-right: $spacing-t4;
      }
    }

    .criteriaItem .header {
      display: flex;
      align-items: center;
      justify-items: flex-start;
      border-bottom: 1px solid #e6e9ec;
      padding: $spacing-t8 0;
      margin-bottom: $spacing-t7;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        :global(.fill-color) {
          color: $color-icon-success;
          fill: $color-icon-success;
        }

        &.exclusion {
          :global(.fill-color) {
            color: $color-red-080;
            fill: $color-red-080;
          }
        }
      }

      .title {
        color: $color-text-default;
        font-size: $font-size-t4;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-t4;
        margin: 0 $spacing-t8;
      }
    }

    .criteriaItem .items {
      > div {
        display: flex;
        flex-direction: column;
        margin: $spacing-t8 $spacing-t9;
        .itemHeader {
          color: $color-text-default;
          font-size: $font-size-t5;
          font-weight: $font-weight-bold;
          line-height: $line-height-t5;
          margin-bottom: $spacing-t8;
        }
      }

      > span {
        color: $color-text-default;
        font-size: $font-size-t5;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-t5;
        margin-left: $spacing-t8;
      }

      .itemWrapper {
        display: flex;
        flex-direction: column;
      }

      .itemContent {
        display: flex;
        align-items: center;
        flex-direction: row !important;
        justify-items: flex-start;
        margin-left: $spacing-t8;
        margin-bottom: $spacing-t8;
      }

      .itemContent .icon {
        display: flex;
        align-items: center;
        flex: 2;

        &.editIcon {
          cursor: pointer;
          :global(.fill-color) {
            color: $color-red-080;
            fill: $color-red-080;
          }
        }
      }
      .itemContent .displayName {
        color: $color-text-default;
        font-size: $font-size-t5;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-t5;
        margin-left: $spacing-t8;
        flex: 10;
      }
    }
  }
}

.scrollableContainer {
  overflow-y: scroll;
  max-height: 200px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
}
