@import "../variables";

.loaderContainer,
.container {
  min-width: 340px;
  max-width: 340px;
  padding-left: $spacing-t4;
  flex: 1;
}

.loaderContainer {
  > div {
    padding: $spacing-t7 !important;
  }
}

.cardContainer {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.campaignContainer {
    padding: $spacing-t9 !important;
    background-color: $color-background-white;
  }

  &.mediaContainer {
    padding: $spacing-t7 $spacing-t6;
    background-color: $color-background-white;
    box-shadow: none !important;
  }

  &.targetGroupContainer {
    padding: 0 !important;
    background-color: $color-surface;
  }

  .child {
    &.campaign {
      padding: $spacing-t7 $spacing-t6;
      background-color: $color-button-bg-white;
      border-radius: $border-radius-medium;
    }
    &.campaign:empty {
      display: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing-t6;
  }

  .contentWrapper {
    display: flex;
    align-items: center;
    .titleContent {
      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: center;

      .targetGroupTitle {
        font-size: $font-size-t4;
        line-height: $line-height-t4;
        max-width: 240px !important;
      }

      .tooltipContent {
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mediaCardTitle {
        font-size: $font-size-t5;
        max-width: 205px !important;
        line-height: $line-height-t5;
      }
      .title {
        color: $color-text-default;
        font-weight: $font-weight-bold;
        overflow: auto;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    cursor: pointer;
    :global(.fill-color) {
      color: $color-icon-default;
      fill: $color-icon-default;
    }
  }

  .cardItemWrapper {
    .itemContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $spacing-t7;
    }

    .itemContainer .labelContainer {
      display: flex;
      align-items: center;

      .label {
        font-size: $font-size-t5;
        font-weight: $font-weight-regular;
        line-height: $line-height-t5;
        color: $color-text-secondary;
      }
    }

    .infoValue {
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t5;
      color: $color-text-default;
      margin-left: $spacing-t8;
      text-transform: capitalize;
    }
  }
}

.panelLoader {
  max-width: 320px;
  margin-bottom: $spacing-t6 !important;
  border: 1px solid $color-border;
}

.panelLoader,
.child.targetGroup > div {
  margin-bottom: $spacing-t7;
  background-color: $color-white;
  box-shadow: $shadow-card;
  border-radius: $border-radius-medium;

  &:last-child {
    margin-bottom: 0;
  }
}

// TODO: Once Tablet / Mobile View is finalized add changes here

.tooltipPopover {
  max-width: 240px;
  z-index: 1000;
  transition: opacity 0.2s ease-in-out;
}

@media (max-width: 768px) {
  .loaderContainer,
  .container {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
}

.summaryText {
  margin-left: 5px;
}
