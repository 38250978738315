@import "../variables";

.removeButton {
  background: transparent !important;
  > div {
    padding: 0 !important;
  }
  :global(.fill-color) {
    fill: $color-red-080 !important;
    color: $color-red-080 !important;
  }
}

.container {
  width: 100%;
  box-shadow: none !important;

  > div > div {
    padding: 0 !important;
    box-shadow: $shadow-card;

    > div > div,
    div[role="columnheader"] {
      background: $color-surface !important;
    }
    div[class="wb-es-container"] {
      box-shadow: none;
    }
  }
}
