@import "../variables";
@import "../icon.mixin.scss";
@import "../button.mixin.scss";

.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0 $spacing-t7 0;

  .labelText {
    padding: 0 0 $spacing-t8 0;
    color: $color-text-secondary;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t5;
  }

  .thirdPartyContent {
    > div {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: $spacing-t6;
    }
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    @include disabled;

    > div {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: $spacing-t6;
    }

    :global(.#{$ns}-numeric-input) {
      :global(.#{$ns}-input-group) {
        width: 100%;
      }
    }

    :global(.#{$ns}-input) {
      font-size: $font-size-t5;
      min-height: 32px !important;
      padding: $spacing-t8 0 $spacing-t8 $spacing-t8 !important;
      border: 1px solid $color-border;
      box-shadow: none;
    }

    .inputError {
      :global(.#{$ns}-input) {
        border: 1px solid $color-red-080;
      }
    }
  }

  .errorList {
    display: flex;
    flex-direction: column;

    > div {
      font-size: $font-size-t5;
      line-height: $line-height-t4;
      color: $color-text-error;
      margin-top: $spacing-t9;
    }
  }
}

.content :global(.#{$ns}-menu-item) {
  padding: $spacing-t8 $spacing-t9 !important;
}

.billingCycle {
  button span {
    color: $color-text-default !important;
    fill: $color-text-default !important;
    font-weight: $font-weight-regular !important;
  }

  :global(.fill-color) {
    fill: $color-text-default !important;
    color: $color-text-default !important;
  }
}

.actions {
  display: flex;
  width: 100%;

  .saveButton {
    margin-right: $spacing-t7;
  }
}

.blockContainer {
  margin-bottom: $spacing-t6;
}
