@import "~@blueprintjs/core/lib/scss/variables";

// Colors

// White
$color-white: #ffffff;

// Grey
$color-grey-100: #0b0d0f;
$color-grey-090: #1b1f26;
$color-grey-080: #2d3540;
$color-grey-070: #475566;
$color-grey-060: #69788c;
$color-grey-050: #8f9eb2;
$color-grey-040: #adbbcc;
$color-grey-030: #cfdce6;
$color-grey-020: #e6ecf0;
$color-grey-010: #f5f8fa;

// Primary
$color-primary-100: #063573;
$color-primary-090: #052f66;
$color-primary-080: #07418c;
$color-primary-070: #084da6;
$color-primary-060: #0a5ecc;
$color-primary-050: #367dd9;
$color-primary-040: #7eabe5;
$color-primary-030: #afcffa;
$color-primary-020: #cce2ff;
$color-primary-010: #e5f0ff;

// Red
$color-red-080: #a8071a;
$color-red-070: #bd111f;
$color-red-060: #cf1322;
$color-red-020: #ffd5d1;

// Amber
$color-amber-080: #ad6800;
$color-amber-070: #d48806;
$color-amber-060: #faad14;
$color-amber-020: #fff1b8;

// Green
$color-green-080: #0a5c1f;
$color-green-070: #0c6b24;
$color-green-060: #0d7c2d;
$color-green-020: #d9f7be;

// TODO: Update Nav bar to actual colors in Figma and remove variables
$color-navbar: rgba(250, 250, 252, 0.44);
$color-navbar-1: rgba(250, 250, 252, 0.15);
$color-admin: $color-primary-090;

// Layout
$color-surface: $color-grey-010;
$color-background-white: $color-white;
$color-card: $color-white;
$color-border: $color-grey-030;
$color-default-hover: $color-grey-030;
$color-primary-hover: $color-primary-070;
$color-green-hover: $color-green-070;
$color-amber-hover: $color-amber-070;
$color-red-hover: $color-red-070;
$color-line-separator: $color-grey-020;
$color-breadcrumb-background: $color-grey-010;
$color-primary-border: $color-primary-060;

// Tables
$color-tables-header: $color-white;
$color-tables-zebra-light: $color-white;
$color-tables-zebra-dark: $color-grey-010;
$color-tables-zebra-darkest: $color-grey-020;
$color-tables-highlight: $color-primary-010;
$color-tables-highlight-border: $color-primary-060;
$color-tables-border: $color-grey-030;

// Text Color
$color-text-default: $color-grey-080;
$color-text-secondary: $color-grey-060;
$color-text-primary: $color-primary-060;
$color-text-white: $color-grey-010;
$color-text-error: $color-red-080;
$color-text-warning: $color-amber-080;
$color-text-success: $color-green-080;
$color-text-disabled: $color-grey-040;
$color-text-success: $color-green-080;
$color-text-button-default: $color-grey-080;
$color-text-button-secondary: $color-grey-060;
$color-text-button-disabled: $color-grey-040;
$color-text-button-white: $color-grey-010;
$color-text-tag-default: $color-grey-080;
$color-text-tag-primary: $color-primary-080;
$color-text-tag-white: $color-grey-010;
$color-text-tag-error: $color-red-080;
$color-text-tag-warning: $color-amber-080;
$color-text-tag-success: $color-green-080;
$color-text-placeholder: $color-grey-060;

// Icon Colors
$color-icon-default: $color-grey-080;
$color-icon-primary: $color-primary-060;
$color-icon-secondary: $color-grey-060;
$color-icon-white: $color-grey-010;
$color-icon-error: $color-red-060;
$color-icon-warning: $color-amber-060;
$color-icon-success: $color-green-060;
$color-icon-disabled: $color-grey-040;

// Button Colors
$color-button-bg-default: $color-grey-020;
$color-button-bg-primary: $color-primary-060;
$color-button-bg-primary-2: $color-primary-020;
$color-button-bg-primary-3: $color-primary-010;
$color-button-bg-disabled: $color-grey-020;
$color-button-bg-white: $color-grey-010;
$color-button-bg-red: $color-red-080;
$color-button-bg-green: $color-green-080;
$color-button-bg-amber: $color-amber-060;

// Alerts
$color-dark-alert-error: $color-red-060;
$color-dark-alert-warning: $color-amber-060;
$color-dark-alert-success: $color-green-060;

// Tags & Badges
$color-tags-bg-default: $color-grey-020;
$color-tags-bg-primary: $color-primary-020;
$color-tags-bg-red: $color-red-020;
$color-tags-bg-amber: $color-amber-020;
$color-tags-bg-green: $color-green-020;

// Gradients
$admin-gradient-background: linear-gradient(135deg, $color-admin 0%, $color-admin 100%);
$user-gradient-background: linear-gradient(135deg, $color-primary-060 0%, $color-primary-070 100%);

// Font
$font-family: "objektiv-mk3", "Helvetica Neue", "Arial", sans-serif;

// Font Weight
$font-weight-regular: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 700;

// Font Size
// 1 rem = 16px
$font-size-t6: 0.625rem; // 10px
$font-size-t5: 0.75rem; // 12px
$font-size-t4: 0.875rem; // 14px
$font-size-t3: 1rem; // 16px
$font-size-t2: 1.25rem; // 20px
$font-size-t1: 1.75rem; // 28px

// Line Height
$line-height-t6: 1rem; // 16px
$line-height-t5: 1.25rem; // 20px
$line-height-t4: 1.5rem; // 24px
$line-height-t3: 1.5rem; // 24px
$line-height-t2: 1.75rem; // 28px
$line-height-t1: 2.5rem; // 40px

// Spacing
$spacing-t10: 0.125rem; // 2px
$spacing-t9: 0.25rem; // 4px
$spacing-t8: 0.5rem; // 8px
$spacing-t7: 0.75rem; // 12px
$spacing-t6: 1rem; // 16px
$spacing-t5: 1.25rem;
$spacing-t4: 1.5rem; // 24px
$spacing-t3: 2rem; // 32px
$spacing-t2: 3rem; // 48px
$spacing-t1: 4rem; // 64px

// Letter Spacing
$letter-spacing-medium: -0.0125rem; // -0.2px

// Icon Size
$icon-size-small: 1rem; // 16px
$icon-size-medium: 1.5rem; // 24px
$icon-size-large: 2.25rem; // 36px

// Border Radius
$border-radius-small: 0.125rem; // 2px
$border-radius-medium: 0.25rem; // 4px
$border-radius-x-medium: 0.375rem; // 4px
$border-radius-large: 0.5rem; // 8px
$border-radius-circle: 50%;
$border-radius-pill: 9999px;

// Shadow
$shadow-card: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
$shadow-modal: 0 0 0 1px rgba(151, 153, 189, 0.1), 0 8px 14px 0 rgba(46, 34, 23, 0.08);
$shadow-heading: 0 0 8px rgba(71, 77, 179, 0.12);
$shadow-default: 0px 2px 4px rgba(4, 38, 82, 0.06);

// Right Panel
$right-panel-width: 360px;
$right-panel-min-width: 320px;
