@import "../variables";
@import "../button.mixin.scss";

$_actionType: "Add", "Update";
$_color: (
  "Add": $color-text-success,
  "Update": $color-text-warning,
);

@each $_type in $_actionType {
  .#{$_type} {
    display: flex;
    svg > rect {
      fill: map_get($_color, $_type) !important;
    }
  }
}

.actionCell {
  display: flex;
  align-items: center;
}

.radioButton {
  display: flex !important;
  align-items: center;
  border-bottom: 1px solid $color-border;
  :global(input:checked ~ .#{$ns}-control-indicator) {
    outline: none !important;
    background-color: $color-button-bg-primary !important;
  }
}
.mergeButton {
  @include buttonWithIcon($color-button-bg-primary);
  margin: $spacing-t6 0;
}

.searchField {
  width: 50%;
}

.rejectedVerificationContainer {
  padding-top: $spacing-t6;
}

.newText {
  color: $color-text-success;
}

.oldText {
  color: $color-text-error;
  text-decoration: line-through;
}
