@import "variables";

.container {
  cursor: pointer;
  display: flex;
}

.checkbox {
  display: flex;
  :global(.fill-color) {
    fill: $color-icon-primary;
  }
  svg {
    height: 24px;
    width: 24px;
  }
}

.label {
  line-height: $line-height-t3;
  padding-left: $spacing-t8;
  display: inline-block;
}

.adjustIcon {
  display: flex;
  flex-shrink: 0;
  :global(.fill-color) {
    fill: $color-icon-default;
  }
}

.notAllowed {
  cursor: not-allowed;
}

.disabled {
  color: $color-text-disabled;
  pointer-events: none;
}
