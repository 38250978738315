@import "../variables";

.timePicker {
  display: flex;
  align-items: flex-end;
}

.errorMessage {
  margin-top: -$spacing-t6 !important;
}

.defaultField {
  margin-top: $spacing-t7;
}
