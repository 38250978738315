@import "../variables";
@import "../text-ellipsize.mixin";

.tabMenu {
  float: left;
  width: 23%;
  height: 72vh;
  overflow: scroll;
  margin-right: $spacing-t6;
  padding: $spacing-t9;
  background: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $shadow-default;

  .tabItems {
    border-radius: $border-radius-medium;
    align-items: center;
    padding: $spacing-t7/2 $spacing-t7;
    margin-bottom: $spacing-t7;
    color: $color-text-default;
    border: 1px solid transparent;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    cursor: pointer;
    @include text-ellipsize;
  }

  :hover {
    background-color: $color-button-bg-default;
  }

  .isActive {
    border: 1px solid $color-primary-border;
    font-weight: $font-weight-semi-bold;
    background-color: $color-button-bg-primary-3;
  }
}

.settingDetails {
  width: 75%;
  float: left;
}

.editPanel {
  padding: $spacing-t9;

  .screenInfo {
    color: $color-text-default;
    font-size: $font-size-t4;
    line-height: $line-height-t4;
    margin-bottom: $spacing-t7;
  }

  .lastUpdatedInfo {
    margin: $spacing-t7 0;
    padding: $spacing-t7;
    background-color: $color-grey-010;
    border-radius: $border-radius-medium;

    .lastUpdatedBy,
    .lastUpdatedTime {
      color: $color-text-secondary;
      font-size: $font-size-t4;
      line-height: $line-height-t4;
      margin-bottom: $spacing-t4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-t4;
    margin-top: $spacing-t7;
    padding-top: $spacing-t7;
    border-top: 1px solid $color-grey-020;
  }
}

.remarksContainer {
  .remarks {
    color: $color-text-default;
    font-size: $font-size-t4;
    line-height: $line-height-t4;
    margin-bottom: $spacing-t7;
  }

  .remarksMetaInfo {
    color: $color-text-secondary;
    font-size: $font-size-t4;
    line-height: $line-height-t4;

    .lastUpdatedBy,
    .lastUpdatedTime {
      margin-bottom: $spacing-t4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.clearButton {
  margin-left: $spacing-t8;
  color: $color-text-secondary;
  font-size: $font-size-t4;
  line-height: $line-height-t4;
  cursor: pointer;
}
