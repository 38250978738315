@import "variables";
@import "form.mixin.scss";

.inputContainer {
  textArea {
    @include inputStyle();
  }
}

.criteriaTypeRadioGroup {
  display: flex;
  margin: $spacing-t7 0;
}

.criteriaTypeLabel {
  color: $color-text-default;
  font-size: $font-size-t5;
  font-weight: $font-weight-semi-bold;
  margin-bottom: $spacing-t6;
}

.invalidIdsContainer {
  margin-top: $spacing-t7;
}

.invalidIdsHeader {
  color: $color-text-error;
  font-size: $font-size-t4;
  font-weight: $font-weight-semi-bold;
  margin-bottom: $spacing-t6;
}

.validIdsHeader {
  color: $color-text-success;
  font-size: $font-size-t4;
  font-weight: $font-weight-semi-bold;
  margin-bottom: $spacing-t6;
}

.invalidIdsContent {
  display: flex;
  flex-direction: column;
  gap: $spacing-t6;
  margin-top: $spacing-t6;
}
