@import "variables";
@import "~basscss/css/basscss-cp.css";
@import "media.mixin";
@import "typograpy";
@import "workbench.variables";
@import "~workbench/src/styles/index.scss";
@import "~flexboxgrid-sass/flexboxgrid.scss";
@import "~prefab/src/styles/index.scss";

html,
body,
#root,
main {
  width: 100%;
  height: 100%;

  @include for-size(phone-only) {
    overflow: hidden;
  }
}

body {
  margin: 0;
  padding: 0;
  background: $color-surface;
  font-family: $font-family !important;
  line-height: $line-height-t6;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox
  ::-webkit-scrollbar {
    display: none; //chrome
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }

  * {
    letter-spacing: $letter-spacing-medium;
  }

  *:not(input),
  *:focus:not(input, textarea) {
    -webkit-touch-callout: none;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    outline-style: none; /*IE*/
  }
}

.cursor {
  cursor: pointer;
}

main {
  display: flex;
  @include for-size(phone-only) {
    display: block;
  }
}

.text-ellipsize {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-justify {
  justify-content: space-between;
}

.fill-color {
  fill: $color-icon-white;
}

:focus {
  outline: none !important;
}

a {
  text-decoration: none !important;
  &:hover {
    border: none !important;
  }
}

.white {
  color: #fff;
}

.align-center {
  align-items: center;
}

.align-top {
  vertical-align: top;
}

.danger {
  color: $color-red-080;
}

.no-select {
  user-select: all !important;
}

.justify-top {
  align-items: flex-start;
  justify-content: space-around;
}

.cursor-auto {
  cursor: auto;
}

.align-base {
  align-content: baseline;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.valignCenter {
  display: grid;
  align-items: center;
}

.paddingX1 {
  padding: 0 $spacing-t8;
}

.paddingY1 {
  padding: $spacing-t8 0;
}

.marginX1 {
  margin: 0 $spacing-t8;
}

.marginY1 {
  margin: 0 $spacing-t8;
}

.toast {
  z-index: 999;
}

.flex-1 {
  flex: 1;
}
// TODO : Should it in the separate PopOver.module.scss
:global(.#{$ns}-portal) {
  :global(.#{$ns}-transition-container) {
    z-index: 100;
  }
}
