@import "variables";
@import "media.mixin";

.infoMenuContainer {
  li > div {
    padding: $spacing-t7/2 $spacing-t7;
  }
  .popupMenuText {
    text-decoration: none;
    color: $color-text-default;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
  }
  &.admin {
    li > div:hover {
      background: $color-grey-020;
    }
  }
  :global(.fill-color) {
    fill: $color-grey-050;
  }
}

.container {
  color: $color-text-white;
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-t6 $spacing-t7;

  .info,
  .help {
    padding: $spacing-t9 $spacing-t7;
    border-radius: $border-radius-medium;
    display: flex;
    align-items: center;
    margin-right: $spacing-t8;
    span {
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      font-weight: $font-weight-semi-bold;
      color: $color-text-white;
      margin-left: $spacing-t9;
      padding: $spacing-t10 0;
    }

    :global(.fill-color) {
      color: $color-icon-white;
      fill: $color-icon-white;
    }

    &:hover {
      background: $color-primary-050;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    :global(.#{$ns}-popover-wrapper) {
      width: 100% !important;
      :global(.#{$ns}-popover-target) {
        width: 100% !important;
      }
    }

    .userInitial {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-primary-070;
      border-radius: $border-radius-medium * 2;
      padding: $spacing-t7/2 $spacing-t8;
      padding-left: $spacing-t7;

      > span {
        color: $color-text-white;
        font-size: $font-size-t5;
        line-height: $line-height-t5;
        font-weight: $font-weight-semi-bold;
      }

      :global(.fill-color) {
        color: $color-icon-white;
        fill: $color-icon-white;
      }
    }

    .toggleButton {
      border-radius: $border-radius-large !important;
      padding: 0 !important;
      min-width: 40px;
      min-height: 40px;
      background: $color-primary-080 !important;
      margin-right: $spacing-t6;

      &.toggleButtonActive {
        background: $color-primary-080 !important;
        svg {
          transform: rotate(-45deg);
        }
      }

      &:hover {
        background: $color-primary-070 !important;
      }

      &.admin {
        background: $color-grey-090 !important;
        &.toggleButtonActive {
          background: $color-grey-090 !important;
        }
        &:hover {
          background: $color-grey-080 !important;
        }
      }

      :global(.fill-color) {
        fill: $color-icon-white !important;
      }

      @include for-size(phone-only) {
        display: none !important;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: $spacing-t7;
  }

  .infoWrapper {
    width: auto;
    margin: 0;
    button {
      padding: 0;
    }
  }

  &.admin {
    .info,
    .help {
      &:hover {
        background: $color-grey-050 !important;
      }
    }

    .userInitial {
      background: $color-grey-090;

      :global(.fill-color) {
        color: $color-grey-050;
        fill: $color-grey-050;
      }
    }
  }
}

.FooterControls {
  display: flex;
  background: $color-background-white;
  padding: $spacing-t6 0 $spacing-t6 $spacing-t6;
  margin-top: $spacing-t10/2;
  border-top: 1px solid $color-border;
  border-bottom-right-radius: $border-radius-large;
  border-bottom-left-radius: $border-radius-large;

  @media only screen and (min-width: 300px) and (max-width: 680px) {
    margin: $spacing-t10/2 0 $spacing-t6 0;
  }
}
