@import "../variables";
@import "../text-ellipsize.mixin";

.rightPanelContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.addException {
  align-self: flex-start;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $spacing-t9 !important;
  .title {
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    font-weight: $font-weight-semi-bold;
    color: $color-grey-060;
  }
}

.clearButton {
  background: none !important;
  > div {
    padding: 0 !important;
  }
}

.allContentButton {
  border-radius: $border-radius-medium;
  border: 1px solid $color-grey-020;
  background: $color-grey-010 !important;
  margin-left: $spacing-t8 !important;
  margin-right: 0 !important;
  cursor: default;

  > div {
    padding: $spacing-t9 $spacing-t8 !important;
    min-width: 94px;

    > span {
      color: $color-grey-080;
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t4;
    }
  }

  &:hover {
    background: $color-grey-010 !important;
  }
}

.errorWrapper {
  flex-direction: column;
  width: 100%;
  > div {
    font-size: $font-size-t5;
    line-height: $line-height-t4;
    color: $color-text-error;
  }
}

.container {
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $spacing-t7;
  > .inputWrapper {
    padding: 0 $spacing-t9 !important;
    > div {
      margin-bottom: 0;
    }
  }

  > .formWrapper {
    align-items: center;
    justify-content: flex-start;
    margin-bottom: $spacing-t9;

    .deleteIcon {
      margin-left: $spacing-t8 !important;
      display: flex;
      align-items: center;
      cursor: pointer;
      :global(.fill-color) {
        color: $color-red-080;
        fill: $color-red-080;
      }
    }

    > .contentInputWrapper {
      flex: 4;
      align-items: center;
      min-width: 105px;
      max-width: 105px;

      > div {
        margin-bottom: 0;
        padding: 0 !important;
      }

      .exceptionIcon {
        :global(.fill-color) {
          color: $color-icon-default;
          fill: $color-icon-default;
        }
      }
    }

    > .timeInputWrapper {
      padding: 0 !important;

      > div {
        margin-bottom: 0;
        min-width: 90px;
        padding: 0 $spacing-t9 !important;
      }

      // Separator
      > div:nth-child(2) {
        margin-bottom: 0 !important;
        min-width: 15px;
        padding: 0 !important;
      }

      > .dividerText {
        align-self: center;
        margin: 0 $spacing-t9 !important;
        font-size: $font-size-t5 !important;
        color: $color-text-default !important;
      }

      .timePicker {
        border-radius: $border-radius-large !important;
        border: 1px solid $color-border;
        padding: $spacing-t9 $spacing-t8;
        :global(.#{$ns}-timepicker-input-row) {
          font-size: $font-size-t5 !important;
          line-height: $line-height-t4;
          box-shadow: none;
          color: $color-text-default !important;
          height: auto;
          border: 0;

          > :global(.#{$ns}-button-text) {
            margin: 0;
          }

          > :global(.#{$ns}-timepicker-input) {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }

      &.allContentWrapper {
        margin-left: $spacing-t8;
      }
    }

    .emptyCancelIcon {
      display: flex;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-right: $spacing-t9;
    }

    > .contentInputWrapper .contentInput {
      padding-right: $spacing-t8 !important;
      padding-left: 0 !important;
      button {
        border-radius: $border-radius-large !important;
        border: 1px solid $color-border;
        font-size: $font-size-t5 !important;
        line-height: $line-height-t4;
        box-shadow: none;
        color: $color-text-default !important;
        max-width: 72px !important;
        min-width: 72px !important;
        padding: 0 !important;

        > div {
          max-width: 72px !important;
          min-width: 72px !important;
          padding: $spacing-t9;

          div:nth-child(1) {
            display: inline-block;
            flex: 1;
            @include text-ellipsize;
          }
          span:nth-child(2) {
            margin: 0;
          }
        }
      }
    }
  }
}
