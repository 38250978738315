@import "variables";

.popup {
  background: $color-background-white;
  :global(.#{$ns}-dialog-header) {
    box-shadow: none;
    border: 1px solid #e1e1e8;
    border-radius: $border-radius-large $border-radius-large 0 0;
    padding: $spacing-t5 $spacing-t6;
    :global(.#{$ns}-heading),
    :global(.#{$ns}-icon) {
      color: $color-text-default;
      font-size: $font-size-t2;
      line-height: $line-height-t2;
    }
  }

  :global(.#{$ns}-dialog-body) {
    margin: $spacing-t6;
  }

  :global(.#{$ns}-form-helper-text) {
    color: $color-text-secondary;
  }

  :global(.#{$ns}-dialog-footer) {
    margin: 0 $spacing-t6;
  }

  :global(.#{$ns}-form-group) {
    margin: 0;
  }

  :global(.#{$ns}-button.#{$ns}-intent-primary) {
    background-color: $color-button-bg-primary;
    &:hover {
      background-color: $color-button-bg-primary;
    }
  }
}
