@import "../variables";
@import "../icon.mixin";
@import "../button.mixin";

@mixin respbutton {
  margin: $spacing-t8 $spacing-t9 0 0 !important;
}
@mixin controls_margin {
  margin: 0 $spacing-t6 $spacing-t6 0;
}
.button {
  @include icon-style-primary;
  color: $color-button-bg-primary !important;
  background-color: $color-button-bg-default !important;
  height: 32px !important;
  border-radius: $border-radius-large !important;
}

.done {
  :global(.fill-color) {
    color: $color-icon-success;
    fill: $color-icon-success;
  }
}

.cancel {
  :global(.fill-color) {
    color: $color-icon-error;
    fill: $color-icon-error;
  }
}

.icon {
  @include icon-style-primary;
}

.bullet {
  border-radius: $border-radius-circle;
  width: $line-height-t5;
  height: $line-height-t5;
  align-self: center;
}

.form {
  width: 100%;
  padding: 5px 20px;
  text-align: left;
}

.buttonActive {
  display: flex;
  height: 32px;
  width: 5rem;
  justify-content: space-between;
  align-items: center;
  color: $color-button-bg-green;
  margin: 0 $spacing-t6 0 0 !important;
}

.innerCount {
  color: $color-text-white;
  background-color: $color-button-bg-green;
  width: 2rem;
  display: flex;
  height: inherit;
  border-radius: $border-radius-large;
  align-items: center;
  justify-content: center;
}

.tabs {
  :global(.#{$ns}-tab-list) {
    border-bottom: 1px solid #e6e9ec;
    margin: 0px 16px;
    :global(.#{$ns}-tab[aria-selected="true"]) {
      color: $color-text-primary;
    }
    :global(.#{$ns}-tab) {
      &:hover {
        color: $color-text-primary;
      }
    }
    :global(.#{$ns}-tab-indicator) {
      background-color: $color-button-bg-primary;
    }
  }
  :global(.#{$ns}-tab-panel) {
    margin-top: 0 !important;
  }
}

.actionCell {
  padding: 0.5rem 0.75rem !important;
}

.active {
  color: $color-text-primary;
  cursor: pointer;
}

.textArea {
  min-height: 230px;
}

.body {
  overflow: scroll;
  max-height: 130px;
  margin: 0px;
}

.right {
  width: 100%;
  text-align: right;
}

.monoSpace {
  font-family: monospace, "objektiv-mk3";
}

.pt24 {
  padding-top: 24px;
}

.pb20 {
  padding-bottom: 20px;
}

.py24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pb24 {
  padding-bottom: 24px;
}

.ml1 {
  margin-left: $spacing-t8;
}

.iconButton {
  @include buttonStyle;
}
.syncButton {
  margin: 0 $spacing-t6 0 0 !important;
}

.save {
  border-radius: 10px !important;
  background-color: $color-button-bg-primary !important;
  color: $color-text-white !important;
  font-size: 12px !important;
  font-weight: $font-weight-semi-bold;
  line-height: 18px;
  padding: 0 24px !important;
}

.searchTag {
  :global(.Form_formgroup) {
    margin: 0px !important;
  }
}
.controlContainer {
  padding: $spacing-t7 0;
}
.controls {
  @include controls_margin;
  margin: 0 $spacing-t6 $spacing-t6 0;
}

.appHeaderContainer {
  padding: 0;
}

.textCenter {
  text-align: center !important;
}
.searchContainer {
  padding: $spacing-t7 0;
  align-items: center !important;
  width: calc(6 / 12 * 100%) !important;
}
.searchBar {
  @include controls_margin;
  width: calc(10 / 12 * 100%) !important;
}
.filter {
  @include controls_margin;
  margin-left: auto;
}
.headerButton {
  padding-right: $spacing-t6 !important;
}

.mergeButton {
  margin-right: $spacing-t6 !important;
}

.truncate {
  white-space: initial;
}

//Will move it to separate file later.
//Media quries for Responsive view.
@media only screen and (min-width: 320px) and (max-width: 1098px) {
  .searchContainer {
    width: 100% !important;
  }
  .searchBar {
    width: calc(8 / 12 * 100%) !important;
  }
}
