@import "variables";
.ganttChart {
  display: flex;
  width: 300px;
  font-family: "Courier New", Courier, monospace;

  svg {
    height: fit-content;
    // g:nth-child(2) {
    //   rect {
    //     fill: none;
    //   }
    // }
    // g:nth-child(3) {
    //   rect {
    //     fill: none;
    //     opacity: 0;
    //   }
    //   line {
    //     stroke-width: 0;
    //   }
    // }
    g:nth-child(5) {
      rect {
        // fill: #0a5ecc2f;
        stroke: #0a5ecc;
        stroke-width: 0.5;
        fill-opacity: 0.3;
        // rx: 5px;
        // ry: 5px;
      }
      text {
        fill: #182026;
      }
    }

    g:nth-child(7) {
      rect {
        // rx: 5px;
        // ry: 5px;
        stroke-width: 0.2;
      }
    }
    // g:nth-child(8) {
    //   path {
    //     fill: #b90acc32;
    //     stroke: #b90acc;
    //   }
    // }

    // g:nth-child(9) {
    //   text {
    //     fill: #182026;
    //     font-family: "Courier New", Courier, monospace;
    //   }
    // }
    // g:nth-child(10) {
    //   text {
    //     fill: #182026;
    //   }
    //   rect {
    //     stroke: #797979;
    //     stroke-width: 1px;
    //   }
    // }
  }
}
