@import "variables";
@import "media.mixin";
@import "form.mixin";

.navContainer {
  > .navHeader {
    min-height: 80px;
    display: flex;
    width: 100%;
    opacity: 1;
    z-index: 99;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px $color-primary-050;
  }

  > .navigationBar {
    border-bottom: solid 1px $color-primary-050;
    height: calc(100vh - 200px);
    overflow: scroll;
  }

  &.expandedContainer {
    > .navigationBar {
      padding: $spacing-t6;
    }
  }

  &.adminNavContainer {
    > .navigationBar,
    > .navHeader {
      border-bottom: solid 1px $color-grey-070;
    }
  }
}
