@import "variables";

.container {
  margin: $spacing-t6 $spacing-t4;
  margin-bottom: 0;
}

.tab {
  margin: 0 $spacing-t6 0 0;
  background-color: $color-button-bg-default !important;
  color: $color-text-primary !important;
  font-size: $font-size-t5 !important;
  line-height: $line-height-t5 !important;
  font-weight: $font-weight-semi-bold !important;
  border-radius: $border-radius-medium;
  &.active {
    background-color: $color-button-bg-primary !important;
    color: $color-text-white !important;
  }
}

@media (max-width: 560px) {
  .tab {
    margin: 0 $spacing-t6 $spacing-t8 0;
  }
}
