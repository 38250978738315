.subComponent {
  // blue box shadow
  box-shadow: inset 0.25rem 0 0 0 #0a5ecc;
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  padding: 10px 40px 10px 50px;
  background: rgba(221, 221, 235, 0.45);
  // styles for font
  color: #2d3540;
  font-family: objektiv-mk3, Helvetica Neue, Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
}
.subComponent:hover {
  background: rgba(0, 0, 0, 0.03);
}
.flexAlign {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.row {
  display: flex;
}
.cell2 {
  flex: 2 1 0%;
}
.cell1 {
  flex: 1 1 0%;
}
