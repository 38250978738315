@import "variables";

.lighter {
  font-weight: 100;
}
.regular {
  font-weight: $font-weight-regular !important;
}
.semi {
  font-weight: $font-weight-semi-bold !important;
}
.bold {
  font-weight: $font-weight-bold !important;
}
.h6 {
  font-size: $font-size-t6 !important;
}
.h5 {
  font-size: $font-size-t5 !important;
}
.h4 {
  font-size: $font-size-t4 !important;
}
.h3 {
  font-size: $font-size-t3 !important;
}
.h2 {
  font-size: $font-size-t1 !important;
}
