.scrollableContainer {
  overflow-y: scroll;
  max-height: 400px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.tableHeader {
  text-align: center;
  padding: 8px;
  border: 1px solid #ddd;
}

.rowNumber {
  text-align: center;
  padding: 8px;
  border: 1px solid #ddd;
  color: #888;
  font-size: 12px;
  width: 75px;
}

.invalidId {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
  font-family: monospace;
  font-size: 14px;
}

.errorText {
  color: red;
}

.validCount {
  color: green;
}

.invalidCount {
  color: red;
}

.uploadedFileContainer {
  margin: 0 10px 0 10px;
}

.loadingContainer {
  margin: 10px 0 0 10px;
}
