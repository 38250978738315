@import "variables";
@import "media.mixin";
@import "./icon.mixin";

.dragDropOption {
  white-space: nowrap;
  font-size: $font-size-t5;
  line-height: $line-height-t5;
  color: $color-grey-060;
  margin-top: $spacing-t7/2;
  @include for-size(phone-only) {
    display: none;
  }
}

.fileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px dashed $color-grey-030;
  box-sizing: border-box;
  border-radius: $border-radius-x-medium;
  padding: $spacing-t4;
  margin-bottom: $spacing-t6;

  &.active {
    border-color: transparent;
    background: $color-primary-060;

    .dragDropOption {
      color: $color-text-white;
    }
  }

  button {
    width: max-content;
  }
}

.fileContainer {
  background: $color-grey-010;
  border-radius: $border-radius-x-medium;
  align-self: center;
  margin: 0 $spacing-t8;
  margin-bottom: $spacing-t6;
  .fileDetails {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .name,
  .fileName {
    flex: 2;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t3;
    color: $color-grey-080;
    padding: $spacing-t7;
    word-wrap: break-word;
    overflow: auto;
  }
  .remove {
    cursor: pointer;
    :global(.fill-color) {
      color: $color-red-070 !important;
      fill: $color-red-070 !important;
    }
  }
}
.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 100%;
  background: $color-grey-010;
  border-radius: $border-radius-x-medium;
  margin-bottom: $spacing-t6;

  .loader {
    border-radius: 50%;
    border: 4px solid $color-grey-020;
    border-top: 4px solid $color-grey-070;
    width: 32px;
    height: 32px;
    @include rotate;
  }

  span {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t3;
    color: $color-grey-080;
    padding: $spacing-t7;

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      animation: ellipsis steps(5, end) 900ms infinite;
      content: "\2026";
      width: 0px;
    }

    @keyframes ellipsis {
      to {
        width: 20px;
      }
    }
  }
}

.errorMessage {
  font-size: $font-size-t5;
  color: $color-text-error;
  padding: 0 0 $spacing-t8 $spacing-t8;
}
