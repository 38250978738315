@import "variables";
@import "icon.mixin";

.button {
  @include icon-style-primary;
  color: $color-text-primary !important;
  background-color: $color-button-bg-default !important;
  height: 32px !important;
  border-radius: $border-radius-large !important;
}

@mixin disabled {
  :global(.#{$ns}-disabled) {
    :global(.#{$ns}-button-text) {
      color: $color-text-button-disabled;
    }
  }
}

@mixin buttonStyle {
  font-size: $font-size-t5 !important;
  font-weight: $font-weight-semi-bold;
  padding: $spacing-t9 $spacing-t9 $spacing-t9 $spacing-t7 !important;
  align-items: center;
  justify-content: center;
  @extend .button;
  :global(.#{$ns}-button-text) {
    margin-right: $spacing-t8;
  }
  :global(.fill-color) {
    color: $color-icon-primary;
    fill: $color-icon-primary;
  }
}
@mixin cancelButton {
  height: 32px !important;
  border-radius: 0.5rem !important;
  padding: 0px 2rem !important;
  border: 1px solid $color-border !important;
  color: $color-text-secondary !important;
  &:hover {
    background-color: $color-button-bg-default !important;
  }
}

@mixin buttonWithIcon($bg-color) {
  background-color: $bg-color !important;
  height: 32px !important;
  border-radius: $border-radius-large !important;
  padding: 0px !important;
  :global(.#{$ns}-button-text) {
    color: $color-text-white !important;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    margin-right: 1rem;
    font-weight: $font-weight-semi-bold;
  }
  :global(.#{$ns}-icon) {
    height: 32px;
    display: flex;
    align-items: center;
    color: $color-text-white !important;
    padding: 0.5rem;
  }
}

@mixin includeButtonWithIcon {
  @include icon-style-primary;
  padding: $spacing-t9 $spacing-t6 !important;
  border-radius: $border-radius-large !important;
  background-color: $color-button-bg-default !important;
  box-shadow: none !important;
  margin-left: $spacing-t6;
  font-size: $font-size-t5;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-t5;
  color: $color-text-success !important;
  display: flex;
  align-items: center;

  :global(.fill-color) {
    color: $color-icon-success;
    fill: $color-icon-success;
  }
  &:hover {
    background-color: $color-button-bg-green !important;
    color: $color-text-white !important;
    :global(.fill-color) {
      color: $color-icon-white;
      fill: $color-icon-white;
    }
  }
  &:disabled {
    :global(.fill-color) {
      color: $color-icon-disabled;
      fill: $color-icon-disabled;
    }
    color: $color-text-success !important;
    background-color: $color-button-bg-disabled !important;
  }
}

@mixin excludeButtonWithIcon {
  @include icon-style-primary;
  padding: $spacing-t9 $spacing-t6 !important;
  border-radius: $border-radius-large !important;
  background-color: $color-button-bg-default !important;
  box-shadow: none !important;
  margin-left: $spacing-t6;
  font-size: $font-size-t5;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-t5;
  color: $color-text-error !important;
  //SLATE-1447 Dec18 Anushree:- spacing added on top of exclude button
  //margin-top: $spacing-t10;
  display: flex;
  align-items: center;

  :global(.fill-color) {
    color: $color-icon-error;
    fill: $color-icon-error;
  }
  &:hover {
    background-color: $color-button-bg-red !important;
    color: $color-text-white !important;

    :global(.fill-color) {
      color: $color-icon-white;
      fill: $color-icon-white;
    }
  }
  //SLATE-1447 Dec18 Anushree:- disable styles available outside hover
  &:disabled {
    // :global(.fill-color) {
    //   color: $color-icon-error;
    //   fill: $color-icon-error;
    // }
    :global(.fill-color) {
      color: $color-icon-disabled;
      fill: $color-icon-disabled;
    }
    color: $color-text-error !important;
    background-color: $color-button-bg-disabled !important;
  }
}

@mixin buttonWithoutIcon($bg-color) {
  background-color: $bg-color !important;
  height: 32px !important;
  border-radius: $border-radius-large !important;
  padding: 0px !important;
  :global(.#{$ns}-button-text) {
    color: $color-text-white !important;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    font-weight: $font-weight-semi-bold;
  }
  :global(.#{$ns}-icon) {
    height: 32px;
    display: flex;
    align-items: center;
    color: $color-text-white !important;
    padding: 0.5rem;
  }
}
