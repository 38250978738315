@import "variables";

@mixin inputStyle() {
  border-radius: $border-radius-x-medium !important;
  border: 1px solid $color-border;
  font-size: $font-size-t5 !important;
  line-height: $line-height-t5 !important;
  font-weight: $font-weight-regular !important;
  user-select: all !important;
  box-shadow: none;
  color: $color-text-default;
  &:focus,
  &:active {
    outline: 1px solid $color-primary-border;
    border: 1px solid $color-primary-border;
    box-shadow: 0 0 0 0 $color-button-bg-primary-2, 0 0 0 0 $color-button-bg-primary-2,
      inset 0 0 0 1px $color-button-bg-primary-2, inset 0 1px 1px $color-button-bg-primary-2;
  }

  ::placeholder {
    color: $color-text-placeholder;
  }
}

@mixin button($bg-color: null) {
  border: 1px solid $color-border !important;
  background-color: $bg-color !important;
  color: $color-text-default !important;
  padding: $spacing-t9 $spacing-t8;
  min-height: 32px !important;
  border-radius: $border-radius-medium !important;
  font-size: $font-size-t5 !important;
}
@mixin headerText {
  color: $color-text-default;
  font-size: $font-size-t3 !important;
  line-height: $line-height-t3;
  font-weight: $font-weight-semi-bold !important;
  letter-spacing: $letter-spacing-medium;
}
@mixin valueText {
  color: $color-text-default;
}
@mixin labelField {
  padding: $spacing-t9 0;
  color: $color-text-default;
}
@mixin formGroup {
  margin-bottom: $spacing-t6 !important;
}

@mixin formContainer {
  padding: 0 $spacing-t4;
  :global(.#{$ns}-tab-list) {
    margin: 0;
  }
}

@mixin errorText {
  font-size: $font-size-t5;
  color: $color-text-error;
  padding-top: $spacing-t7;
}
