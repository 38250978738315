@import "variables";

.popOver {
  box-shadow: $shadow-card !important;
  width: 100% !important;
  z-index: 101;
  :global(.#{$ns}-transition-container) {
    z-index: 101;
  }
}

.popOverMenuItem {
  display: flex;
  align-items: center !important;
  color: $color-text-secondary;

  :global(.#{$ns}-text-overflow-ellipsis) {
    white-space: normal !important;
  }
}

.popOverIcon {
  display: flex;
}
