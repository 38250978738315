@import "variables";
@import "icon.mixin";
@import "form.mixin";

.block {
  margin-bottom: $spacing-t3;
  border-radius: $border-radius-large;

  &.primary {
    box-shadow: $shadow-card;
    background-color: $color-background-white;
  }

  &.secondary {
    background-color: $color-surface !important;
    box-shadow: none !important;
  }

  &.inline {
    background-color: $color-background-white !important;
    box-shadow: none !important;
  }
}

.cardContainer {
  display: flex;
  overflow: auto;
  width: "100%";
  box-sizing: "border-box";
  max-width: 100%;
  margin-bottom: $spacing-t5;
  .card {
    padding: $spacing-t7;
    background-color: white;
    margin-right: $spacing-t6; // space between two cards
    border-radius: $border-radius-medium;
    min-height: 115px;
    box-shadow: $shadow-default;
  }
  .itemContainer {
    .primary {
      font-size: $font-size-t2;
      padding-top: $spacing-t9;
      color: $color-text-default;

      span {
        font-weight: $font-weight-semi-bold;
      }
    }
    .secondary {
      color: $color-text-secondary;
      font-size: $font-size-t5;
      padding-top: $spacing-t7;
      span {
        font-weight: $font-weight-semi-bold;
      }
    }
  }
}

.label {
  color: $color-text-default;
  font-size: $font-size-t5 !important;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-t5;
  text-transform: capitalize;
}

.textValue {
  color: $color-text-default;
  font-size: $font-size-t4 !important;
  line-height: $line-height-t4;
  overflow: auto;
  text-overflow: ellipsis;
}

.tableBlock {
  margin-bottom: $spacing-t3;
}

.tableContainer {
  padding: $spacing-t6 $spacing-t4;
  padding-top: 0;
  background: $color-background-white;
}

.wrapper {
  padding-right: $spacing-t8;
}
