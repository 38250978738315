@import "variables";
@import "icon.mixin";

.searchIcon {
  border-radius: $border-radius-medium;
  padding: 0 !important;
  z-index: 1;
  width: $spacing-t4 !important;
  margin: $spacing-t9;

  :global(.fill-color) {
    color: $color-icon-secondary;
    fill: $color-icon-secondary;
  }
}
.left {
  position: inherit !important;
}
