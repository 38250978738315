@import "../variables";
@import "../form.mixin.scss";
@import "../button.mixin.scss";

.mergeButton {
  @include buttonWithIcon($color-button-bg-primary);
}

.tabContainer {
  padding: 0;
}

.downIcon {
  :global(.fill-color) {
    fill: #9799bd !important;
  }
}

.rightIcon {
  margin-left: $spacing-t9;
}

.actionCell {
  padding: $spacing-t8 $spacing-t7 !important;
}

.innerTable {
  background-color: $color-tables-zebra-darkest !important;
}

.button {
  @include icon-style-primary;
  color: $color-text-primary !important;
  background-color: $color-button-bg-default !important;
  height: 32px !important;
  border-radius: $border-radius-large !important;
}

.icon {
  @include icon-style-primary;
}

.pageHeadWrapper {
  //margin: $spacing-t6 $spacing-t4;
  padding: $spacing-t9 $spacing-t6 $spacing-t6 $spacing-t6;
}
