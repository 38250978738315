@import "variables";
@import "form.mixin.scss";

@mixin size {
  height: 2.25rem !important;
  width: 2.25rem !important;
}

@mixin bg {
  background-color: $color-primary-hover !important;
}

.dateRangePicker {
  :global(.#{$ns}-input-group):nth-child(1) {
    flex-shrink: unset;
    input {
      @include inputStyle();
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border: 1px solid $color-border;
      height: $spacing-t3 !important;
      &:focus {
        border: 1px solid $color-primary-border !important;
      }
    }
  }
  :global(.#{$ns}-input-group):nth-child(2) {
    flex-shrink: unset;
    input {
      @include inputStyle();
      border: 1px solid $color-border;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      height: $spacing-t3 !important;

      &:focus {
        border: 1px solid $color-primary-border !important;
      }
    }
  }
  :global(.#{$ns}-daterangepicker) {
    box-shadow: $shadow-card;
  }

  :global(.#{$ns}-timepicker) {
    padding-right: $spacing-t6;
  }

  :global(.#{$ns}-timepicker-input-row) {
    display: flex;
    align-items: center;
    box-shadow: none;
    border: 1px solid $color-border;
    border-radius: $border-radius-x-medium;
    padding: 0 !important;
    input {
      box-shadow: none;

      &:focus,
      &:active {
        box-shadow: 0 0 0 0 $color-button-bg-primary-2, 0 0 0 0 $color-button-bg-primary-2,
          inset 0 0 0 1px $color-button-bg-primary-2, inset 0 1px 1px $color-primary-020;
        border: 1px solid $color-primary-border;
      }
    }
  }

  &.error {
    input {
      border-color: $color-red-060 !important;
    }
  }

  &.disabled {
    input {
      pointer-events: none;
      cursor: not-allowed;
      background-color: $color-button-bg-disabled;
    }
  }
}

.daypicker {
  :global(.DayPicker-Day--outside) {
    visibility: visible !important;
  }
  :global(.DayPicker-Day--selected-range--start),
  :global(.DayPicker-Day--selected-range--end) {
    background-color: $color-button-bg-primary !important;
    color: $color-text-white !important;
  }
  :global(.DayPicker-Day--selected-range) {
    background-color: $color-primary-010 !important;
    color: $color-primary-080 !important;
  }
  :global(.DayPicker-Day--hovered-range) {
    background-color: $color-primary-020 !important;
    color: $color-primary-080 !important;
  }
  :global(.DayPicker-Day--selected) {
    @include size;
    background-color: $color-primary-border !important;
  }
  :global(.DayPicker-Day) {
    @include size;
    &:hover {
      background: $color-button-bg-primary-2 !important;
      color: $color-primary-080 !important;
    }
  }
}
