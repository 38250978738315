@import "variables";

.content {
  :global(.#{$ns}-transition-container) {
    z-index: 99;
  }
  :global(.#{$ns}-popover-content) {
    display: flex;
    align-items: center;
    padding: $spacing-t8 $spacing-t7;
    line-height: $line-height-t4 !important;
    span {
      font-size: $font-size-t5 !important;
      line-height: $line-height-t5 !important;
    }
  }
}

.toolTip {
  margin-right: $spacing-t7;
  display: flex;
  @extend .content;

  max-width: 200px;

  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;

  @media (max-width: 600px) {
    max-width: 150px;
  }
}
