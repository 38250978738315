@import "variables";

.menuWrapper {
  color: $color-text-white;
  padding: 0 $spacing-t7;
  border-radius: $border-radius-medium;
  user-select: all !important;
  margin: $spacing-t10 0;

  &:hover {
    background-color: $color-navbar-1;
  }

  :global(.fill-color) {
    fill: $color-text-white;
  }

  .menuText {
    height: 40px;
    display: flex;
    align-items: center;
    color: $color-grey-030;
    font-style: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
  }

  .badge {
    color: $color-text-white;
    background-image: linear-gradient(
      0deg,
      rgba(173, 181, 220, 0.2) 0%,
      rgba(214, 220, 255, 0.2) 100%
    );
    padding: $spacing-t9 $spacing-t8;
    line-height: $line-height-t3;
    border-radius: 18px / 100% !important; // Sets an additional radius, so we can have elliptical corners
    font-size: $font-size-t6 !important;
    font-weight: $font-weight-semi-bold;
    height: 1.15rem;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .persistentBadge {
    position: absolute;
    font-size: $font-size-t6;
    background-color: $color-red-080;
    color: $color-text-white;
    right: 10px;
    top: 0px;
    padding: $spacing-t10 $spacing-t7/2;
    border-radius: $border-radius-large;
  }

  .red {
    background-color: $color-red-080;
  }

  &.selectedMenu {
    background-color: $color-navbar-1;
    color: $color-text-white !important;

    :global(.fill-color) {
      fill: $color-icon-white !important;
    }

    .menuText {
      font-weight: $font-weight-semi-bold;
      color: $color-text-white;
    }
  }
}
