@import "variables";

.timePicker {
  display: flex;

  :global(.#{$ns}-timepicker) {
    padding-right: $spacing-t6;
  }

  :global(.#{$ns}-timepicker-input-row) {
    display: flex;
    align-items: center;
    box-shadow: none;
    border: 1px solid $color-border;
    border-radius: $border-radius-x-medium;

    input {
      box-shadow: none;

      &:focus,
      &:active {
        box-shadow: 0 0 0 0 $color-button-bg-primary-2, 0 0 0 0 $color-button-bg-primary-2,
          inset 0 0 0 1px $color-button-bg-primary-2, inset 0 1px 1px $color-primary-020;
        border: 1px solid $color-primary-border;
      }
    }
  }

  .iconWrapper {
    display: flex;
    align-items: center;

    :global(.fill-color) {
      color: $color-text-default;
      fill: $color-text-default;
    }
  }

  &.error {
    > div {
      border-color: $color-red-060 !important;
    }
  }
}
