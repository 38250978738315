@import "./variables.scss";
@import "./button.mixin.scss";
//icon color

.green {
  :global(.fill-color) {
    color: $color-icon-success;
    fill: $color-icon-success;
  }
}
.red {
  :global(.fill-color) {
    color: $color-icon-error;
    fill: $color-icon-error;
  }
}
