@import "./variables";

.listItems {
  > div:nth-child(1) {
    border-top-left-radius: $border-radius-medium;
    border-top-right-radius: $border-radius-medium;
  }

  > div:last-child {
    border-bottom-left-radius: $border-radius-medium;
    border-bottom-right-radius: $border-radius-medium;
  }
}

.listItem {
  box-shadow: $shadow-default;
  padding: $spacing-t6 $spacing-t4;
  border-bottom: 1px solid $color-grey-020;
  background-color: $color-background-white;

  .title {
    color: $color-text-default;
    font-size: $font-size-t4;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t4;
    padding: $spacing-t9 0;
  }

  .sectionContent {
    .contentName {
      display: flex;
      align-items: center;
      flex: 3;

      > span {
        color: $color-text-default;
        font-size: $font-size-t5;
        line-height: $line-height-t4;
        padding: $spacing-t9 0;
      }

      :global(.fill-color) {
        color: $color-text-default;
        fill: $color-text-default;
      }

      > svg {
        margin-right: $spacing-t8;
      }
    }

    .durations {
      flex: 3;
      color: $color-text-default;
      font-size: $font-size-t5;
      line-height: $line-height-t4;
      padding: $spacing-t9 0;
    }
  }

  .overbooking {
    color: $color-text-default;
    font-size: $font-size-t5;
    line-height: $line-height-t4;
    padding: $spacing-t9 0;
  }

  .statusContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;

    .status {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .text {
        color: $color-text-default;
        font-size: $font-size-t5;
        line-height: $line-height-t4;
        padding: $spacing-t9 0;
      }
    }

    .validity {
      flex: 3;
      color: $color-text-default;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      padding: $spacing-t9 0;
    }
  }

  .actions > * {
    display: flex;
    justify-content: flex-end;
  }

  .toolTip {
    margin: 0 !important;
  }

  .statusIconTooltip {
    margin: 0;
  }

  @media (max-width: 768px) {
    .sectionContent {
      .contentName {
        flex: 2;
      }

      .durations {
        flex: 1;
      }
    }
  }
}

.remarksContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.remarksContent {
  color: $color-text-default;
  font-size: $font-size-t5;
  line-height: $line-height-t4;
  padding: $spacing-t9 0;
}

.remarksMetaInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.lastUpdatedBy,
.lastUpdatedTime {
  display: flex;
  flex-direction: column;
}

.metaLabel {
  font-size: $font-size-t5;
  line-height: $line-height-t5;
  color: $color-grey-050;
  margin-bottom: $spacing-t9;
}

.metaValue {
  font-size: $font-size-t5;
  line-height: $line-height-t5;
  color: $color-text-default;
}

@media (max-width: 768px) {
  .remarksMetaInfo {
    flex-direction: column;
    gap: $spacing-t6;
  }

  .lastUpdatedBy {
    margin-bottom: $spacing-t6;
  }
}
