@import "./variables";

.icon {
  :global(.fill-color) {
    color: $color-icon-primary;
    fill: $color-icon-primary;
  }
}

.buttonGroupLabel {
  color: $color-text-secondary;
  align-items: center;
  margin: 0 $spacing-t6 0 0;
  font-size: $spacing-t7;
  font-weight: bold;
}

.toggle {
  background-color: $color-button-bg-default !important;
  height: 32px !important;
  border-radius: $border-radius-large !important;
  box-shadow: none !important;
  :global(.#{$ns}-button) {
    margin: 0 !important;
    box-shadow: none !important;
    border-radius: $border-radius-large !important;
    background-color: transparent !important;
    font-size: $font-size-t5 !important;
  }
}

.add {
  padding: 0.25rem 1.25rem 0.25rem 1.75rem !important;
}
