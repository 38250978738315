@import "variables";
@import "media.mixin";

.body {
  text-align: center;
  color: $color-text-secondary;
}

.container {
  padding-bottom: $spacing-t3 * 2;
  min-width: 100%;

  @include for-size(phone-only) {
    padding-bottom: $spacing-t6;
  }
}

.reportButton {
  margin-top: $spacing-t5;
  background-color: $color-button-bg-primary !important;
  border-radius: $border-radius-large !important;
  color: $color-text-white !important;
  font-size: $font-size-t5 !important;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-t4;
  padding: 0 $spacing-t4 !important;
  min-height: $spacing-t3 !important;
}

.reloadButton {
  margin-top: $spacing-t5;
  color: $color-text-secondary !important;
  border-radius: $border-radius-large !important;
  font-size: $font-size-t5 !important;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-t4;
  padding: 0 $spacing-t4 !important;
  min-height: $spacing-t3 !important;
  &:hover {
    background-color: $color-button-bg-default !important;
  }
}

.buttonAlign {
  display: flex;
  align-items: center;
  flex-direction: column;
}
