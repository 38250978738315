@import "../variables";
@import "../button.mixin";

.container {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  gap: $spacing-t6;
  margin-bottom: $spacing-t8;
}

.button {
  &.uploadCsvButton,
  &.selectByIdButton {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 150px;
  }

  &.include {
    @include includeButtonWithIcon;
  }

  &.exclude {
    @include excludeButtonWithIcon;
  }
}

.contentWrapper {
  display: flex;
  justify-items: flex-start;

  .selector {
    flex: 2;
    margin-right: $spacing-t6;
    display: flex;
    flex-direction: column;

    .title {
      color: $color-text-default;
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t5;
      margin: $spacing-t8 0;
    }

    :global(.#{$ns}-popover-target) {
      width: 100%;
    }
  }

  .criteriaInput {
    flex: 4;
    display: flex;
    align-items: flex-end;
    justify-items: flex-start;

    :global(.#{$ns}-button) {
      min-width: 350px !important;
    }
  }

  .actions {
    flex: 5;
    align-self: flex-end;
    display: flex;
  }
}

.criteriaTextInput {
  :global(.#{$ns}-input) {
    min-width: 350px !important;
  }
}

.infoIconToolTip {
  margin-left: $spacing-t9;
  align-items: center;
  display: flex;
  justify-content: center;
}
