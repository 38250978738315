@import "../variables";

.container {
  text-align: center;
}
.heading {
  font-size: $font-size-t1;
  line-height: $line-height-t1;
  padding: $spacing-t6;
  color: $color-text-default;
  text-align: center;
}

.body {
  color: $color-text-default;
}
