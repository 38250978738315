@import "variables";
@import "media.mixin";
@import "form.mixin";

.admin {
  background-color: $color-grey-080 !important;

  :global(.fill-color) {
    fill: $color-icon-white;
  }
}

.mobileHeader {
  display: none !important;
  width: 100%;
  top: 0;
  height: 3.5rem;
  color: $color-text-white;
  background-color: $color-primary-060;
  align-items: center;
  transition: all 0.1s ease-in-out;

  .menuIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0rem;
    color: $color-icon-white;

    :global(.fill-color) {
      fill: $color-icon-white;
      color: $color-icon-white;
    }
  }

  .slateLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include for-size(phone-only) {
    display: flex !important;
    position: sticky;
    z-index: 9;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  z-index: 100;
  opacity: 0.3;
  height: 100%;
  background: $color-grey-080;
}

.sideNavContainer {
  position: absolute;
  left: 0;
  animation: moveForward 0.2s;

  &.collapsed {
    width: 100px;
    background-color: $color-primary-060;
    height: 100vh;
    float: left;
    overflow: hidden;
    user-select: all !important;
    flex-shrink: 0;

    @include for-size(phone-only) {
      display: none;
    }
  }

  &.expanded {
    background-color: $color-primary-060;
    height: 100vh;
    float: left;
    width: 272px;
    overflow: hidden;
    flex-shrink: 0;
  }

  &.hovered {
    width: 272px;
    position: absolute;
    z-index: 101;
    height: 100vh;
    overflow: hidden;
    background-color: $color-primary-060;
  }

  &.portal {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    z-index: 101;
  }
}

.pageLayout {
  font-family: $font-family;
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-grow: 1;
  margin-left: 100px;

  .container {
    padding-bottom: $spacing-t3 * 2;
    display: inline-block;
    overflow: auto;
  }

  &.page_expanded {
    margin-left: 272px;
  }

  &.page_collapsed {
    margin-left: 100px;
  }

  @include for-size(phone-only) {
    height: 85vh;
    width: 100%;

    .container {
      padding-bottom: $spacing-t6;
    }

    &.mobile {
      margin-left: 0;
    }
  }
}

.linkWrapper {
  width: 6px;
}

.hide {
  display: none;
}

@keyframes moveForward {
  from {
    left: -230px;
  }

  to {
    left: 0px;
  }
}

.formContainer {
  @include formContainer;
}
