@import "../variables";

.similarBrands {
  padding: $spacing-t8 0;
}
.brandsList {
  padding: $spacing-t9 0;
}
.brand {
  padding: $spacing-t9 0;
  color: $color-text-primary;
  cursor: pointer;
}
