@import "variables";

.placeholder {
  position: relative;
  height: 48px;
  width: 328px;
  border-style: dashed;
  border-color: $color-grey-060;
  border-width: 1px;
  border-radius: $border-radius-large;
  &:before {
    position: absolute;
    display: block;
    top: 50%;
    left: 40%;
    transform: translateY(-50%);
    line-height: normal;
    content: "Drop here";
    color: $color-text-secondary;
  }
}

.checkbox {
  border-radius: $border-radius-large;
  background: $color-surface;
  padding: $spacing-t8;
  margin-top: $spacing-t8;
  margin-bottom: $spacing-t8;
  display: flex !important;
  align-items: unset;
}

.hideText {
  opacity: 0;
}
