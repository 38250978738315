@import "../variables";
@import "../button.mixin.scss";

.mapButtonWrapper {
  display: flex;
  align-items: flex-start;
  margin: -0.5rem 0rem;
}

.encrypted {
  :global(.fill-color) {
    fill: $color-red-080;
  }
}

.encryptedWithKey,
.unEncrypted {
  :global(.fill-color) {
    fill: $color-icon-success;
  }
}

.certifications {
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  padding: $spacing-t7 0;
  margin-bottom: $spacing-t7;

  .addCertificationButton {
    text-align: right;

    :global(.#{$ns}-button-text) {
      color: $color-text-primary;
    }
  }
}
