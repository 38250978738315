@import "./variables";
@import "./Button.module.scss";

.actionButtonsContainer {
  margin: $spacing-t6 0;
  padding: $spacing-t6;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: $color-white;
  box-shadow: $shadow-heading;
  border-radius: $border-radius-x-medium;
  transition: all 10s ease-in;

  .buttonList {
    flex-wrap: wrap;
  }
}

.selectionInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-t5;
  margin-bottom: $spacing-t5;

  &:empty {
    display: none;
  }

  .selectionInfo {
    padding: $spacing-t9;
    margin-right: $spacing-t7;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    color: $color-grey-090;
    span {
      padding: $spacing-t9 $spacing-t7;
      margin-right: $spacing-t9;
      background: $color-grey-020;
      color: $color-grey-080;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      font-weight: $font-weight-semi-bold;
      border-radius: 50px;
    }
  }
}
