@import "variables";

.filterChips {
  background: $color-background-white;
  padding: $spacing-t7;
  border-bottom: 1px solid $color-border;
}

.resultsCount {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-t5;
  line-height: $line-height-t5;
  color: $color-text-default;
}

.chip {
  border-radius: 24px / 100% !important; // Sets an additional radius, so we can have elliptical corners
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-t5 !important;
  line-height: $line-height-t5 !important;
  padding: $spacing-t9 $spacing-t8 !important;
  margin: $spacing-t9 0 $spacing-t9 $spacing-t8;
  min-height: $icon-size-medium;

  &:last-child {
    margin-right: $spacing-t8;
  }

  &.primary {
    background: $color-tags-bg-primary !important;
    background-color: $color-tags-bg-primary !important;
    color: $color-button-bg-primary !important;
  }

  &.danger {
    background: $color-tags-bg-red !important;
    background-color: $color-tags-bg-red !important;
    color: $color-text-error !important;
  }
}
