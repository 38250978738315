@import "../variables";
@import "../form.mixin.scss";
@import "../button.mixin.scss";

.statusTooltip {
  margin-right: $spacing-t10;

  div {
    display: flex !important;
  }
}

.actionItems {
  justify-content: flex-end;
}
