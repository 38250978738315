@import "variables";
@import "icon.mixin";
@import "media.mixin";
@import "text-ellipsize.mixin";

@mixin breadcrumb {
  font-weight: $font-weight-semi-bold !important;
  font-size: $font-size-t5 !important;
  padding: $spacing-t10;
  border-radius: $border-radius-medium;
}

@mixin breadCrumbPadding {
  padding: $spacing-t8 $spacing-t4;
  @include for-size(phone-only) {
    padding: $spacing-t5 $spacing-t7;
  }
}

.wrapper {
  position: sticky;
  top: 0;
  z-index: 9;
  background: $color-breadcrumb-background;

  .container {
    @include breadCrumbPadding;
    display: flex;
    align-items: center;

    z-index: 9;
    background: $color-breadcrumb-background;
    :global(.#{$ns}-breadcrumb-current) {
      color: $color-text-default;
    }
    :global(.#{$ns}-breadcrumbs) > li::after {
      display: none;
    }

    li {
      :global(.fill-color) {
        color: $color-text-button-secondary;
        fill: $color-text-button-secondary;
      }

      &.current {
        @include breadcrumb;
        color: $color-text-default !important;
      }
    }
  }

  .item {
    @include breadcrumb;
    color: $color-text-secondary !important;
    cursor: pointer;
    display: inline-block;
    @include text-ellipsize;
    &:hover {
      background-color: $color-button-bg-primary-2 !important;
      color: $color-text-default !important;
    }
  }
}

.current {
  @include breadcrumb;
  display: inline-block !important;
  @include text-ellipsize;
  color: $color-text-default !important;
}

.overflowMenuItems > div {
  word-break: break-word;
  white-space: normal;
}
