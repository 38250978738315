@import "../variables";
@import "../button.mixin";

.cardContainer {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  background: #ffff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 10px;
  position: relative;
}

.cardHeader {
  display: flex;
  width: 100%;
  height: max-content;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  flex-direction: column;
}

.titleTypeContainer {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.titleType {
  display: flex;
  align-items: center;
  color: $color-icon-secondary;
  font-size: small;
}

.iconColor {
  :global(.fill-color) {
    color: $color-icon-secondary;
    fill: $color-icon-secondary;
  }
}

.cardTitle {
  font-size: 1rem;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;
  color: $color-text-default;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.description {
  padding-left: 2px;
  padding-top: 5px;
  font-size: $font-size-t5;
  color: $color-text-secondary;
  margin-top: 4px;
  max-width: 80%;
  text-overflow: ellipsis;
}
.dividerLine {
  width: 100%;
  height: 1px;
  background: #e5f0ff;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.buttonStyle {
  width: 123px;
  height: 32px;
}

.buttonStyleActive {
  width: fit-content;
  height: max-content;
  color: $color-icon-primary;
  background-color: transparent !important;
}

.buttonStyleScheduled {
  width: fit-content;
  height: 32px;
  background-color: #e6ecf0;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 21px;
  border-radius: 12px;
  background: #e6ecf0;
  color: #0a5ecc;
  font-size: 0.7rem;
  font-weight: 500;
}
.statusIndicator {
  width: 5.33px;
  height: 5.33px;
  border-radius: 50%;
  background: #047b24;
  margin-right: 5px;
}
.bottomRightBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: #69788c;
  font-size: 0.7rem;
}

.buttonBox {
  margin-top: 0.7rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.scheduleTypes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2rem;
  color: #2d3540;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
.emails {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  color: #2d3540;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
.email {
  color: #2d3540;
  font-size: 0.8rem;
  font-weight: 500;
  :hover {
    cursor: pointer;
  }
}
.bottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subFilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2rem;
  color: #0a5ecc;
  :hover {
    cursor: pointer;
  }
}

.progressBar {
  width: 100%;
  height: 8px;
  background: #e6ecf0;
  border-radius: 8px;
  margin-top: 1rem;
}
.progress {
  width: 50%;
  height: 100%;
  // background: #f11717;
  border-radius: 8px;
  transition: 300ms;
}

.mandatoryFiltersContainer {
  // width: max-content;
  height: max-content;
  display: flex;
  gap: 1rem;
  margin-top: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}
.heading {
  font-size: $font-size-t5;
  line-height: $line-height-t5;
  font-weight: $font-weight-semi-bold;
  color: $color-grey-060;
  // text-transform: uppercase;
  width: max-content;
  padding-left: 8px;
  margin-bottom: 2px;
}

.mandatoryFilterContainer {
  user-select: all !important;
  width: max-content;
  // display: flex;
  // flex-direction: column;
}

.filterContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.filterChips {
  background: $color-background-white;
}

.resultsCount {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-t5;
  line-height: $line-height-t5;
  color: $color-text-default;
}

.chip {
  border-radius: 24px / 100% !important; // Sets an additional radius, so we can have elliptical corners
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-t5 !important;
  line-height: $line-height-t5 !important;
  padding: $spacing-t9 $spacing-t8 !important;
  margin: $spacing-t9 0 $spacing-t9 $spacing-t8;
  min-height: $icon-size-medium;

  &:last-child {
    margin-right: $spacing-t8;
  }

  &.primary {
    background: $color-tags-bg-primary !important;
    background-color: $color-tags-bg-primary !important;
    color: $color-button-bg-primary !important;
  }

  &.danger {
    background: $color-tags-bg-red !important;
    background-color: $color-tags-bg-red !important;
    color: $color-text-error !important;
  }
}
