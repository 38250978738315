@import "variables";

.hoursInput {
  display: flex;
  align-items: center;

  :global(.#{$ns}-input) {
    font-size: $font-size-t5;
    width: 48px;
    padding: $spacing-t8 0 $spacing-t8 $spacing-t7 !important;
    border-radius: $border-radius-large !important;
    border: 1px solid $color-border;
    box-shadow: none;
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    :global(.fill-color) {
      color: $color-text-default;
      fill: $color-text-default;
    }
  }
  &.error {
    :global(.#{$ns}-input) {
      border: 1px solid $color-red-060 !important;
    }
  }
}
