@import "../variables";
@import "../button.mixin.scss";

.details {
  margin-top: 0;
}

.statusBadge {
  display: inline-flex;
  align-items: center;
  margin-left: $spacing-t7;
  background-color: $color-grey-020;
  padding: 0 $spacing-t8;
  font-size: $font-size-t5;
  font-weight: $font-weight-semi-bold;
  border-radius: $border-radius-pill;
}

.availibilityBtn {
  margin-left: $spacing-t7;
  padding: $spacing-t8 $spacing-t8;
  font-size: $font-size-t5;
  font-weight: $font-weight-semi-bold;
  color: $color-text-secondary;
  background-color: $color-grey-020;
  border-radius: $border-radius-large;
}

.availibilityBtn:hover {
  background-color: $color-grey-030;
}

.primary {
  color: $color-icon-primary;
  border: 1px solid $color-tags-bg-primary;
}

.green {
  color: $color-text-tag-success;
  border: 1px solid $color-tags-bg-green;
}

.lightBlue {
  //SLATE-1387 Nov17-2023 Anushree:- text and bg color should be different
  //color: $color-tags-bg-primary;
  color: $color-text-tag-primary;
  border: 1px solid $color-tags-bg-primary;
}

.grey {
  color: $color-text-tag-default;
  border: 1px solid $color-tags-bg-default;
}

.yellow {
  color: $color-text-tag-warning;
  border: 1px solid $color-tags-bg-amber;
}

.red {
  color: $color-text-tag-error;
  border: 1px solid $color-tags-bg-red;
}

.purple {
  color: $color-primary-060;
  border: 1px solid $color-tags-bg-primary;
}

.lightGrey {
  color: $color-grey-060;
  border: 1px solid $color-grey-040;
}

.secondaryValue {
  margin-top: $spacing-t8;
  color: $color-text-secondary;
}

.secondaryText {
  color: $color-text-secondary;
}

.cancel {
  margin-right: $spacing-t6;
}

.summaryTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: $font-weight-semi-bold;

  span {
    margin-left: $spacing-t9;
  }

  :global(.fill-color) {
    fill: $color-icon-primary;
    color: $color-icon-primary;
  }
}

.campaignDetailsPage {
  display: flex;
}

.campaignTheatresHeader {
  display: flex;
  padding: 0 0 $spacing-t6 0;
  align-items: center;
}

.targetGroupType {
  padding: $spacing-t8 0;

  > div {
    font-size: $font-size-t5;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t4;
  }
}

.criteria {
  padding: $spacing-t8;
  color: $color-text-secondary;
  font-size: $font-size-t5;
  line-height: $line-height-t4;
}

.typeName {
  padding-left: $spacing-t8;
}

.addButton {
  @include buttonStyle;
  background-color: transparent !important;
  padding: 0 $spacing-t8 !important;

  :global(.fill-color) {
    fill: $color-icon-primary;
  }
}

.createButton {
  background-color: transparent !important;
  display: flex;
  align-items: center;
  > * {
    margin: 0 !important;
  }

  :global(.fill-color) {
    color: $color-icon-primary;
    fill: $color-icon-primary;
  }

  :global(.#{$ns}-button-text) {
    color: $color-text-primary !important;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
  }
}

.placementsTimeName {
  font-size: $font-size-t5;
  font-weight: $font-weight-semi-bold;
  margin-bottom: $spacing-t9;
}

.placementsTimeRange {
  font-size: $font-size-t6;
  color: $color-text-disabled;
  font-weight: $font-weight-semi-bold;
}

.approveButton {
  @include buttonStyle;
  background-color: $color-button-bg-green !important;
  color: $color-text-white !important;
  margin-left: $spacing-t7;
  padding: $spacing-t9 $spacing-t7 !important;

  :global(.fill-color) {
    color: $color-text-white !important;
    fill: $color-text-white !important;
  }

  &:hover {
    background-color: $color-green-070 !important;
  }
}

.pauseButton {
  margin-right: $spacing-t7;
}

.rejectButton {
  @include buttonStyle;
  background-color: $color-red-060 !important;
  color: $color-text-white !important;
  margin-left: $spacing-t7;
  padding: $spacing-t9 $spacing-t7 !important;

  :global(.fill-color) {
    color: $color-text-white !important;
    fill: $color-text-white !important;
  }

  &:hover {
    background-color: rgba($color-red-060, 0.75) !important;
  }
}

.editButton {
  @include buttonStyle;
  background-color: $color-button-bg-primary !important;
  color: $color-text-white !important;
  margin-left: $spacing-t7;
  padding: $spacing-t9 $spacing-t7 !important;

  :global(.fill-color) {
    color: $color-text-white !important;
    fill: $color-text-white !important;
  }

  &:hover {
    background-color: rgba($color-button-bg-primary, 0.75) !important;
  }
}

.tabsContainer {
  :global(.#{$ns}-tab-list) {
    margin-bottom: 0 !important;
  }

  .tabContentWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    > :nth-child(1) {
      flex: 3;
    }

    .tabPanel {
      padding: 0;
    }

    > :nth-child(3) {
      flex: 1;
    }
  }
}

.updateButtonWrapper {
  display: flex;
  align-items: flex-start;

  .updateButton {
    @include buttonStyle;
    background-color: transparent !important;
    padding: 0 $spacing-t8 !important;

    :global(.fill-color) {
      fill: $color-icon-primary;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
}

.collapseActions {
  display: flex;
  align-items: center;
  justify-items: flex-start;

  > .button {
    cursor: pointer;
    background-color: transparent !important;

    :global(.fill-color) {
      color: $color-icon-primary;
      fill: $color-icon-primary;
    }
  }
}

.collapsibleHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $spacing-t6 0;
  width: 100%;
  border-bottom: 1px solid $color-border;
  margin-bottom: $spacing-t4;

  @media (max-width: 768px) {
    flex-direction: column;

    .title {
      margin-bottom: $spacing-t7;
    }
  }

  .title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $font-size-t3;
    font-weight: $font-weight-semi-bold;

    .icon {
      margin: 0 $spacing-t8;
      position: relative;
      transition: all 0.2s ease;

      :global(.fill-color) {
        fill: $color-icon-disabled;
        color: $color-icon-disabled;
      }
    }

    .open {
      transform: rotateZ(0deg);
      top: $spacing-t9;
    }

    .close {
      transform: rotateZ(-90deg);
      top: 0;
    }
  }

  .subTitle {
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .info {
      font-size: $font-size-t5;
      font-weight: $font-weight-regular;
      margin: 0 $spacing-t1;
      display: flex;
      flex-direction: column;

      div {
        margin-bottom: 4px;
      }
    }

    .action {
      margin: 0 $spacing-t4;

      :global(.fill-color) {
        fill: $color-icon-disabled;
        color: $color-icon-disabled;
      }
    }
  }

  .collapsibleActions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > .button {
      cursor: pointer;
      background-color: transparent !important;

      :global(.fill-color) {
        color: $color-icon-primary;
        fill: $color-icon-primary;
      }
    }
  }
}

.collapsibleBodyContainer {
  padding-right: 0;

  > div:nth-child(2) {
    margin-bottom: 0 !important;
  }
}

.loading:after {
  content: "";
  animation: dots 3s steps(3, end) infinite;
}

@keyframes dots {
  0% {
    content: " .";
  }

  40% {
    content: " ..";
  }

  80% {
    content: " ...";
  }
}

.disabledBtn {
  cursor: not-allowed;
  color: $color-text-disabled !important;
  opacity: 0.5;
}

.placementsWrapper {
  min-width: 100%;
}

.infoIconWrapper {
  margin-left: $spacing-t8;
  display: flex;
  align-items: center;
}
