@import "../variables";

.placementWrapper {
  width: 100%;

  .placementTable,
  .placementDetails {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .placementDetails {
    flex-wrap: wrap;
  }

  .placementTable {
    :global(.fill-color) {
      color: $color-grey-080;
      fill: $color-grey-080;
    }
  }
}
