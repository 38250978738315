@import "variables";

.menu {
  color: $color-text-default !important;
  font-size: $font-size-t5 !important;
  min-width: 120px !important;

  :global(.#{$ns}-menu-item):hover {
    background-color: $color-primary-020;
  }

  :global(.#{$ns}-icon) {
    color: $color-icon-primary !important;
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: $color-icon-disabled;
}

:global(.#{$ns}-menu) {
  max-height: 30vh !important;
  overflow: scroll;
  padding: 0 !important;
}
