@mixin icon-style-primary {
  padding: $spacing-t9 !important;
  border-radius: $border-radius-medium;
  :global(.fill-color) {
    color: $color-icon-secondary;
    fill: $color-icon-secondary;
  }
  &:hover {
    background-color: $color-button-bg-primary-2 !important;
    :global(.fill-color) {
      color: $color-icon-primary;
      fill: $color-icon-primary;
    }
  }
}

@mixin rotate {
  -webkit-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite;
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@mixin actionButton($bg-color) {
  background-color: $bg-color !important;
  height: 32px !important;
  border-radius: $border-radius-large !important;
  padding: 0px !important;
  :global(.#{$ns}-button-text) {
    color: $color-text-white !important;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    margin-right: 1rem;
    font-weight: $font-weight-semi-bold;
  }
  :global(.#{$ns}-icon) {
    height: 32px;
    display: flex;
    align-items: center;
    color: $color-text-white !important;
    padding: 0.5rem;
  }
}

@mixin cancelButton {
  height: 32px !important;
  border-radius: 0.5rem !important;
  padding: 0px 2rem !important;
  border: 1px solid $color-border !important;
  color: $color-text-secondary !important;
}
