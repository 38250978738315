@import "./variables";
@import "media.mixin";

.pageContainer {
  padding: $spacing-t4;
}

.summaryContainer {
  padding: 0;
  margin-top: $spacing-t5;
}

.pageWrapper {
  border-radius: $border-radius-large;
  background: $color-surface;

  > * {
    box-shadow: $shadow-default;
  }
}

.viewPageContainer {
  padding: $spacing-t4 0 $spacing-t2;
}

.pageTableContainer {
  padding: $spacing-t4 $spacing-t4 $spacing-t2;
}

.pageTableContainerWithFilter {
  padding: $spacing-t4 0;
}

.statusGroup {
  display: flex;
  flex-wrap: wrap;
  padding: $spacing-t8 $spacing-t9;
  background: $color-white;
  border-radius: $border-radius-medium;
  margin-bottom: $spacing-t6;
  box-shadow: $shadow-default;

  &:empty {
    display: none;
    margin: 0 !important;
    padding: 0 !important;
  }

  &.statusGroupLoading {
    padding: $spacing-t8;
    > * {
      min-height: 40px;
    }
  }
}

@include for-size(phone-only) {
  .pageContainer {
    padding: $spacing-t7;
  }

  .statusGroup {
    margin-bottom: $spacing-t7;
  }
}
