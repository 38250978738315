@import "variables";

.title {
  color: $color-text-default;
}

.active {
  color: $color-text-primary;
  cursor: pointer;
}

.search {
  :global(.#{$ns}-popover-target) {
    width: 95%;
  }
}

.pageShow {
  align-self: center;
  font-weight: bold;
  color: $color-text-secondary;
  padding-right: 0.5rem;
  :global(.#{$ns}-form-group) {
    margin: 0px;
  }
}

.body {
  overflow: scroll;
  max-height: 130px;
  margin: 0px;
}

.right {
  width: 100%;
  text-align: right;
}

.monoSpace {
  font-family: monospace, "objektiv-mk3";
}

.pre {
  white-space: pre-wrap !important;
}

.pt24 {
  padding-top: 24px;
}

.pb20 {
  padding-bottom: 20px;
}

.py24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pb24 {
  padding-bottom: 24px;
}
