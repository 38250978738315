@import "../variables";
@import "../icon.mixin";
@import "../button.mixin";

.container {
  //padding-bottom: $spacing-t6;
  padding: $spacing-t8 $spacing-t6;
  //border-bottom: 1px solid $color-border;
  display: flex;
  // box-shadow: $shadow-default;
  justify-content: space-between;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: $spacing-t7;

  > * {
    margin-left: $spacing-t8;
  }
  .createButton {
    background-color: transparent !important;
    margin-top: $spacing-t7;
    > * {
      margin: 0 !important;
    }

    :global(.fill-color) {
      color: $color-icon-primary;
      fill: $color-icon-primary;
    }

    :global(.#{$ns}-button-text) {
      color: $color-text-primary !important;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
    }
  }
  .resetButton {
    @include cancelButton;
    padding: 0 $spacing-t6 !important;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
  }

  .saveButton {
    @include actionButton($color-button-bg-primary);
    padding: 0 $spacing-t6 !important;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    margin-right: 0;
    :global(.fill-color) {
      color: $color-icon-white;
      fill: $color-icon-white;
    }

    :global(.#{$ns}-button-text) {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.container .stepContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-t9;
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    pointer-events: none;
    border-radius: 8px;
    padding-top: 4px;
    padding-left: 6px;
    padding-bottom: 4px;
    padding-right: 8px;
    width: fit-content;
    text-overflow: ellipsis;
    // border-bottom: ;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $spacing-t9 !important;
      border-radius: 3px;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      font-size: $font-size-t5;
      font-weight: 600;
      color: white;
      //background-color: $color-button-bg-primary;
      :global(.fill-color) {
        color: $color-icon-white;
        fill: $color-icon-white;
      }
    }
    //icon styles
    .pending {
      background-color: $color-icon-disabled;
    }
    .completed {
      background-color: $color-button-bg-primary;
    }

    .name {
      color: $color-text-disabled;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      text-align: center;
      padding-left: $spacing-t8 !important;
      font-weight: 700;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
    .complete {
      color: $color-button-bg-primary;
    }
    &.active {
      cursor: not-allowed !important;
      pointer-events: none !important;
      border: 1px solid $color-button-bg-primary;
      background-color: $color-primary-010;
      .name {
        font-weight: $font-weight-bold;
        color: $color-button-bg-primary;
      }

      .icon {
        background-color: $color-icon-primary;
        padding: $spacing-t7/2 !important;
        :global(.fill-color) {
          color: $color-icon-white;
          fill: $color-icon-white;
        }
      }
    }

    &.valid {
      cursor: pointer;
      pointer-events: all;
    }
  }

  .separator {
    background-color: $color-border;
    width: 40px;
    height: 2px;
    margin: 0 $spacing-t9;
    &:last-child {
      display: none;
    }
  }
  //progress separator styles
  .pending {
    background-color: $color-border;
  }
  .completed {
    background-color: $color-button-bg-primary;
  }
}
