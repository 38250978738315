@import "../variables";
@import "../button.mixin";

.tableColumn,
.headerColumn {
  min-height: 65px;

  > div {
    padding: $spacing-t8;
  }

  > span {
    cursor: pointer;
  }
}

.decrementDisabled {
  pointer-events: none;
  cursor: not-allowed !important;
  :global(.fill-color) {
    color: $color-icon-disabled !important;
    fill: $color-icon-disabled !important;
  }
}
.addAndRemoveIcon {
  height: max-content;
  width: max-content;

  :hover {
    background-color: $color-grey-020;
    border-radius: 20%;
  }
  :global(.fill-color) {
    fill: $color-grey-080;
  }
}

.placementHeader,
.placementContent {
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  > span {
    cursor: pointer;
  }

  > div {
    font-size: $font-size-t5;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t5;
    color: $color-text-default;
  }
}
.disabledCell {
  pointer-events: none;
  cursor: not-allowed !important;
  :global(.fill-color) {
    color: $color-icon-disabled;
    fill: $color-icon-disabled;
  }
}
.labelContainer {
  flex-basis: 38px !important;
  max-width: 38px !important;
  padding-top: 30px !important;
  label {
    color: $color-grey-080;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    font-weight: $font-weight-semi-bold;
  }
}
.labelOr {
  display: flex;
  flex: 1;
  margin: auto;
  text-align: center;
  label {
    color: $color-grey-080;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    font-weight: $font-weight-semi-bold;
  }
}

.pageContent {
  display: flex;
  padding: 0 $spacing-t4;
  > :nth-child(1) {
    flex: 3;
    padding-top: $spacing-t6;
    padding-right: $spacing-t6;
  }

  > :nth-child(3) {
    flex: 1;
  }
}
.campaignTree {
  padding-top: $spacing-t6;
}

.campaignCreateProgressBar {
  position: sticky;
  top: 40px;
  background-color: $color-white;
  z-index: 12;
  display: flex;
  flex-direction: column;
  padding: 0 $spacing-t4;
}

.draftButton {
  margin-right: $spacing-t8 !important;
}

.actions {
  border-top: 1px solid #e6e9ec;
  margin: 0 0 $spacing-t4 0;
  padding: $spacing-t6 0 0 0;
  .resetButton {
    @include cancelButton;
    padding: 0 $spacing-t6 !important;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
  }
  .createButton {
    @include buttonStyle;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    > * {
      margin: 0 !important;
    }

    :global(.fill-color) {
      color: $color-icon-primary;
      fill: $color-icon-primary;
    }

    :global(.#{$ns}-button-text) {
      color: $color-text-primary !important;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
    }
  }
  .saveButton {
    @include actionButton($color-button-bg-primary);
    padding: 0 $spacing-t6 !important;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    margin-right: $spacing-t7;
    :global(.fill-color) {
      color: $color-icon-white;
      fill: $color-icon-white;
    }

    :global(.#{$ns}-button-text) {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.placementItem {
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 $spacing-t9;
}

.childWrapper {
  padding: 0 !important;

  .formContainer {
    padding: $spacing-t6 0 0 $spacing-t6 !important;
    width: 100%;

    &.basicDetails > * {
      padding: 0 $spacing-t6 $spacing-t6 0 !important;
    }

    .contactList {
      display: flex;
      align-items: flex-start;
      margin-top: $spacing-t6;

      > * {
        margin-right: $spacing-t6;
      }

      &:nth-child(1) {
        margin-top: 0;
      }

      .removeButton {
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        height: 100%;
        padding-bottom: $spacing-t9;
      }
    }

    .addItem {
      @extend .button;
      margin-top: $spacing-t8 !important;
      margin-bottom: $spacing-t7;
      margin-left: $spacing-t8;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      padding-left: $spacing-t8;

      > span {
        font-size: $font-size-t5;
        font-weight: $font-weight-bold;
        line-height: $line-height-t5;
        color: $color-text-primary;
      }

      &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        > span {
          color: $color-text-disabled;
        }

        :global(.fill-color) {
          color: $color-text-disabled;
          fill: $color-text-disabled;
        }
      }
    }
  }
}

.mediaFormField {
  justify-self: flex-end;
}

.placementOption {
  cursor: pointer;
  display: flex;
  align-self: center;
  align-items: center;
  padding-bottom: 0 !important;
}

.removeButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.button {
  @include buttonStyle;
  background-color: transparent !important;
  padding: $spacing-t8 !important;
  margin-top: $spacing-t5;

  :global(.fill-color) {
    fill: $color-icon-primary;
  }
}

.timeOfDayHeader {
  display: flex;
  width: max-content;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-t8;
  gap: $spacing-t8;
  .timeOfDayContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 1px;
    .placementsTimeName {
      display: flex;
      align-items: flex-start;
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t5;
      color: $color-text-default;
      padding-bottom: $spacing-t7/2;
    }
    .placementsTimeRange {
      font-size: $font-size-t5;
      font-weight: $font-weight-regular;
      line-height: $line-height-t5;
      color: $color-text-secondary;
    }
  }
}

.disabledBtn {
  cursor: not-allowed;
  color: $color-text-disabled !important;
  opacity: 0.5;
}

.orderId {
  margin-top: 20px;
  // margin-right: 6rem;
}
.childContainer {
  justify-content: start;
}
.alignContainer {
  margin-top: 25px;
}
