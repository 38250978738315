@import "./variables";
@import "./button.mixin.scss";

.saveButton {
  @include buttonWithIcon($color-button-bg-primary);
}

.saveButtonWithoutIcon {
  @include buttonWithoutIcon($color-button-bg-primary);
}

.cancelButton {
  @include cancelButton;
}

.addButton {
  @include buttonStyle;
  @extend .icon;
}

.successButton {
  @include buttonWithIcon($color-button-bg-green);
}

.failureButton {
  @include buttonStyle;
  color: $color-red-060 !important;
  :global(.fill-color) {
    color: $color-red-060 !important;
    fill: $color-red-070 !important;
  }
  &:hover {
    background-color: rgba($color-button-bg-red, 0.15) !important;
  }
}

.iconButton {
  @include buttonStyle;
  @extend .icon;
}

.icon {
  :global(.fill-color) {
    color: $color-icon-primary;
    fill: $color-icon-primary;
  }
}

.active {
  color: $color-text-primary;
  cursor: pointer;
}

.appHeaderContainer {
  padding: 0;
}

.buttonSpacing {
  margin-right: $spacing-t6;
}
