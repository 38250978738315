@import "variables";
@import "form.mixin.scss";

@mixin size {
  height: 2.25rem !important;
  width: 2.25rem !important;
}

@mixin bg {
  background-color: $color-primary-hover !important;
}

.disabled {
  pointer-events: none;
}

.dateRangePicker {
  .separator {
    display: flex;
    margin: $spacing-t8 $spacing-t7;
    color: $color-grey-080;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
  }

  .startDate {
    :global(.#{$ns}-input-group) {
      flex-shrink: unset;
      input {
        @include inputStyle();
        border: 1px solid $color-border;
        height: $spacing-t3 !important;
        &:focus {
          border: 1px solid $color-primary-border !important;
        }
      }
      &:global(.#{$ns}-disabled) {
        input,
        input:focus,
        input:active {
          border: 0;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  .endDate {
    :global(.#{$ns}-input-group) {
      flex-shrink: unset;
      input {
        @include inputStyle();
        border: 1px solid $color-border;
        height: $spacing-t3 !important;

        &:focus {
          border: 1px solid $color-primary-border !important;
        }
      }
      &:global(.#{$ns}-disabled) {
        input,
        input:focus,
        input:active {
          border: 0;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  :global(.#{$ns}-daterangepicker) {
    box-shadow: $shadow-card;
  }

  :global(.#{$ns}-timepicker) {
    padding-right: $spacing-t6;
  }

  :global(.#{$ns}-timepicker-input-row) {
    display: flex;
    align-items: center;
    box-shadow: none;
    border: 1px solid $color-border;
    border-radius: $border-radius-x-medium;
    padding: 0 !important;
    input {
      box-shadow: none;

      &:focus,
      &:active {
        box-shadow: 0 0 0 0 $color-button-bg-primary-2, 0 0 0 0 $color-button-bg-primary-2,
          inset 0 0 0 1px $color-button-bg-primary-2, inset 0 1px 1px $color-primary-020;
        border: 1px solid $color-primary-border;
      }
    }
  }

  .error {
    input {
      border-color: $color-red-060 !important;
    }
  }

  .disabled {
    input {
      pointer-events: none;
      cursor: not-allowed;
      background-color: $color-button-bg-disabled;
    }
  }
}

:global(.#{$ns}-datepicker-timepicker-wrapper) {
  text-align: center;
  &:before {
    content: "(In 24-hour Format)";
    font-size: $font-size-t6;
    margin: 0 $spacing-t7;
    color: $color-text-default;
    order: 1;
  }
  :global(.#{$ns}-timepicker) {
    padding: 0 !important;
    order: 0;
  }
}

.daypicker {
  :global(.DayPicker-Day--outside) {
    visibility: visible !important;
  }
  :global(.DayPicker-Day--selected-range--start),
  :global(.DayPicker-Day--selected-range--end) {
    background-color: $color-button-bg-primary !important;
    color: $color-text-white !important;
  }
  :global(.DayPicker-Day--selected-range) {
    background-color: $color-primary-010 !important;
    color: $color-primary-080 !important;
  }
  :global(.DayPicker-Day--hovered-range) {
    background-color: $color-primary-020 !important;
    color: $color-primary-080 !important;
  }
  :global(.DayPicker-Day--selected) {
    @include size;
    background-color: $color-primary-border !important;
  }
  :global(.DayPicker-Day) {
    @include size;
    &:hover {
      background: $color-button-bg-primary-2 !important;
      color: $color-primary-080 !important;
    }
  }
}
