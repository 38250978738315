@import "variables";
@import "text-ellipsize.mixin";

@mixin subText {
  color: $color-text-secondary;
  text-wrap: wrap;
}

.menu {
  max-height: 30vh !important;
  overflow: scroll;
  min-width: 300px !important;
  color: $color-text-default !important;
  font-size: $font-size-t5 !important;

  :global(.#{$ns}-icon) {
    color: $color-icon-primary !important;
  }
}

.menuItemDisabled {
  opacity: 0.8;
  pointer-events: none;
  cursor: not-allowed;
}

.text {
  color: $color-text-default;
  flex: 1;
  @include text-ellipsize;
  white-space: normal !important;
}

.subText {
  @include subText;
}

.withSecondaryText {
  display: flex;

  .secondaryText {
    font-size: $font-size-t6;
    @include subText;
    white-space: normal !important;
  }
}

.menuItemSelected,
.menuItemSelected:hover {
  background-color: $color-primary-010 !important;
  border: 1px solid $color-primary-060 !important;
  .text,
  .secondaryText {
    color: $color-text-default !important;
  }
  .subText {
    color: $color-text-secondary !important;
  }
}
