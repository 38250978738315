@import "../variables";

.screenFormContainer {
  height: 100%;
  .addButton {
    min-height: 56px;
    display: flex;
    align-items: flex-end;
    align-self: center;
    padding: 0;
  }
}

.screenListContainer {
  padding: 0 !important;
  box-shadow: none !important;
  > div {
    padding: 0 !important;
  }
}

.data {
  color: $color-grey-080;
  font-size: $font-size-t4;
  line-height: $line-height-t4;
  font-weight: $font-weight-bold;
}

.errors {
  color: $color-red-080;
  font-size: $font-size-t5;
  padding: $spacing-t7 !important;
  display: block;
  width: 100%;
}
