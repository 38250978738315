@import "../variables";

.verificationContainer {
  background: $color-background-white;
  width: 100%;
  display: flex;
}

.brandsListContainer {
  box-shadow: $shadow-heading;
  padding: $spacing-t6;
  min-height: 85vh;
}

.brandDetailsWrapper {
  padding: $spacing-t6 $spacing-t8 $spacing-t3 $spacing-t8;
}

.detailsContainer {
  border-right: solid 1px $color-border;
  padding: 0 $spacing-t6 $spacing-t1 $spacing-t6;
  min-height: 85vh;
}

.mergeOptionsContainer {
  padding: $spacing-t6;
  min-height: 85vh;
}

.mergeSelector {
  display: flex;
  padding-bottom: $spacing-t6;
}

.selector {
  width: 100%;
}

.searchToggleButton {
  margin-left: $spacing-t8;
  background-color: transparent;
  border-radius: $border-radius-large !important;
  &:hover,
  &.searchToggleButtonActive {
    background-color: $color-button-bg-default !important;
  }
  :global(.fill-color) {
    color: $color-text-primary !important;
  }
}

.verificationFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: $spacing-t5 $spacing-t3;
  background-color: $color-background-white;
  position: fixed;
  bottom: 0;
  box-shadow: $shadow-heading;
  z-index: 99;
}

.buttonStyles {
  font-size: 12px !important;
  font-weight: $font-weight-semi-bold;
  line-height: 18px;
  border-radius: $border-radius-large !important;
  padding: 0 $spacing-t2 !important;
  min-height: $spacing-t3 !important;
  margin: 0 $spacing-t8 $spacing-t8 $spacing-t8;
  transition: all 0.2s ease-in;
}

.ignoreButton {
  @extend .buttonStyles;
  color: $color-text-error !important;
  background-color: $color-button-bg-default !important;
  &:hover,
  &.active {
    background-color: $color-button-bg-red !important;
    color: $color-text-white !important;
  }
  &:disabled {
    opacity: 0.75;
  }
}

.similarBrandsInfo {
  font-size: $font-size-t5;
  font-weight: $font-weight-bold;
  color: $color-text-primary;
  text-align: center;
  margin-bottom: $spacing-t9;
}

.actionButtonContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  margin: $spacing-t6 0;
}

.brandSection {
  margin-bottom: $spacing-t3;
}

.brandTitle {
  font-size: $font-size-t3;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-t3;
  border-bottom: solid 1px $color-border;
  padding-bottom: $spacing-t7;
  margin-bottom: $spacing-t7;
}

.brandItem {
  cursor: pointer;
  padding: $spacing-t9 $spacing-t8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: $border-radius-medium;
  margin: $spacing-t7 0;
  &:hover,
  &.brandItemActive {
    background-color: $color-button-bg-primary-2;
  }
  &.brandItemDisabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.brandItemName {
  display: flex;
  align-items: center;
}

.successIcon {
  :global(.fill-color) {
    fill: $color-icon-success !important;
  }
}

.errorIcon {
  :global(.fill-color) {
    fill: $color-icon-error !important;
  }
}

.iconContainer {
  display: flex;
  align-self: start;
  svg {
    display: flex;
    :global(.fill-color) {
      fill: $color-icon-primary;
    }
  }
}

.customTextWithLabel {
  padding: $spacing-t6 $spacing-t8 $spacing-t6 0;
}

.newText {
  color: $color-text-success;
}
.oldText {
  color: $color-text-error;
  text-decoration: line-through;
}

.submitButton,
.approveButton {
  margin-right: $spacing-t6 !important;
}
.cancelButton .rejectButton {
  padding: 0 $spacing-t2 !important;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
