@import "variables";
@import "media.mixin";

.popMenuItemIcon {
  :global(.fill-color) {
    fill: $color-grey-050;
  }
}

.link {
  .menuButton {
    margin: 0;
    width: 100%;
    margin-bottom: $spacing-t8;
  }

  &:last-child .menuButton {
    margin-bottom: 0;
  }
}

.profileButton {
  &.admin {
    background: $color-grey-080 !important;

    &:hover {
      background: $color-grey-090 !important;
    }
  }
}
.profileContainer {
  display: flex;
  align-items: center;
  .content {
    min-width: 165px;
    .userName {
      margin-bottom: $spacing-t9;
      max-width: 165px;
      line-height: $line-height-t5;
    }

    .companyName {
      max-width: 165px;
      text-align: left;
    }

    @include for-size(phone-only) {
      min-width: 210px;
      .companyName,
      .userName {
        max-width: 210px;
      }
    }
  }

  > div {
    :global(.fill-color) {
      fill: $color-icon-white;
    }
  }

  @include for-size(phone-only) {
    justify-content: flex-start;
  }
}

.profileMenuContainer {
  border-radius: $border-radius-medium;
  li > div {
    padding: $spacing-t7/2 $spacing-t7;
    background: $color-grey-020;

    .popupMenuText {
      overflow: hidden;
      max-width: 155px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $color-text-default;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
    }

    &:hover {
      background: $color-primary-010;
    }

    :global(.fill-color) {
      fill: $color-grey-050;
    }
  }

  &.admin {
    li > div:hover {
      background: $color-grey-020;
    }
  }
}

.active {
  background: linear-gradient(0deg, #adb5dc 0%, #d6dcff 100%) !important ;
}
