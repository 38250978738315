@import "../variables";

.fileCards {
  display: flex;
  justify-content: space-between;
  border-radius: $border-radius-large;
  padding: $spacing-t6;
  align-items: center;
  margin-bottom: $spacing-t8;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);

  .fileName {
    padding-bottom: $spacing-t8;
  }

  .fileType {
    font-size: $font-size-t5;
    color: #8384a3;
  }
}
