@import "variables";

.wrapper {
  margin: $spacing-t8 0;
  width: 100%;
}

.table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: solid 1px $color-tables-border;
  border-collapse: collapse;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-t5;
  overflow-x: auto;
  max-width: fit-content;
}

@mixin columnStyle {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing-t8;
  min-width: 80px;
  height: 100%;
}

@mixin rowStyle {
  display: flex;
  width: auto;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.column {
  @include columnStyle();
  &:not(:last-child) {
    border-right: solid 1px $color-tables-border;
  }
}
.row {
  @include rowStyle();
  &:not(:last-child) {
    border-bottom: solid 1px $color-tables-border;
  }
}

.columnHeader {
  @include columnStyle();
  &:not(:last-child) {
    border-right: solid 1px $color-tables-border;
  }
}
.rowHeader {
  @include rowStyle();
  border-bottom: solid 1px $color-tables-border;
}
