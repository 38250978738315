@import "variables";
@keyframes easyIn {
  from {
    right: -450px;
  }
  to {
    right: 0px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}

@mixin space {
  margin: 0 $spacing-t8;
}

.overlay {
  position: absolute;
  z-index: 100;
  opacity: 0.3;
  height: 100%;
  width: 100%;
  background: $color-grey-080;
  animation: fadein 2s;
}

.container {
  width: $right-panel-width;
  position: absolute;
  z-index: 100;
  height: 100%;
  right: 0;
  animation: easyIn 0.5s ease-in;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(151, 153, 189, 0.1), 0 2px 4px 0 rgba(16, 22, 26, 0.2),
    0 8px 14px 0 rgba(46, 34, 23, 0.08);
}

.wrapper {
  overflow: hidden;
  overflow-y: scroll;
  padding: $spacing-t6;
  height: calc(100vh - 75px);
}

.wrapperWithFooter {
  height: calc(100% - 130px);
}

.header {
  color: #1c283f;
  font-size: $font-size-t3;
  font-weight: $font-weight-semi-bold;
  border-bottom: 1px solid #e1e1e8;
  padding: $spacing-t5 $spacing-t6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :global(.#{$ns}-button) {
    min-height: $spacing-t3;
    min-width: $spacing-t3;
  }
  :global(.#{$ns}-button):hover {
    background-color: $color-primary-hover !important;
    :global(.#{$ns}-icon) {
      color: $color-icon-white;
      fill: $color-icon-white;
    }
  }

  > div {
    color: $color-text-default;
    font-size: $font-size-t2;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t2;
  }
}

.overlayMask {
  display: flex;
  width: auto;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.footer {
  flex: 0 1 auto;
  border-top: 1px solid #e1e1e8;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: $spacing-t7 $spacing-t6;
  background: $color-background-white;
}
.save {
  border-radius: $border-radius-large !important;
  color: $color-text-white !important;
  font-size: $font-size-t5 !important;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-t4;
  padding: 0 $spacing-t4 !important;
  min-height: $spacing-t3 !important;
}

.cancelButton {
  margin: 0 $spacing-t6;
  color: $color-text-secondary !important;
  border-radius: $border-radius-large !important;
  font-size: $font-size-t5 !important;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-t4;
  padding: 0 $spacing-t4 !important;
  min-height: $spacing-t3 !important;
  &:hover {
    background-color: $color-button-bg-default !important;
  }
}

.add {
  background-color: $color-button-bg-primary !important;
  &:not(:first-child) {
    margin-left: $spacing-t7;
  }
  @extend .save;
}

.update {
  background-color: $color-button-bg-green !important;
  @extend .save;
}

@media (max-width: 320px) {
  .container {
    width: $right-panel-min-width;
  }
}

@media (max-width: 480px) {
  .wrapperWithFooter {
    height: calc(100% - 175px);
  }
}
