@import "../variables";

.placementContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  justify-content: center !important;
  .title {
    font-size: $font-size-t5;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t4;
    color: $color-text-default;
  }

  &.selectAll {
    justify-content: flex-start !important;
  }
}

.timeOfDayHeader {
  .timeOfDayContent {
    .placementsTimeName {
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t4;
      color: $color-text-default;
    }
  }
}

.columnContainer .timeOfDayHeader,
.columnContainer .column,
.headerContainer .header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.errors {
  color: $color-red-080;
  font-size: $font-size-t5;
}

.checkboxContainer {
  width: auto !important;
  :global(.fill-color) {
    color: $color-icon-secondary !important;
    fill: $color-icon-secondary !important;
  }

  &.checked {
    :global(.fill-color) {
      color: $color-icon-primary !important;
      fill: $color-icon-primary !important;
    }
  }

  &.disabled {
    :global(.fill-color) {
      color: $color-icon-disabled !important;
      fill: $color-icon-disabled !important;
    }
  }
}

.totalPlays {
  color: $color-grey-080;
  font-size: $font-size-t5;
  line-height: $line-height-t5;
  font-weight: $font-weight-semi-bold;
}
