@import "./variables";
@import "./icon.mixin";

.statusIconLegend {
  display: inline-flex;
  align-items: center;
  padding: $spacing-t8 $spacing-t7;
  .statusCount {
    margin-right: $spacing-t9;
    font-weight: $font-weight-bold;
  }
}

.cursorPointer {
  cursor: pointer;
}
