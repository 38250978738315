@import "../variables";

.scheduleWrapper {
  .scheduleDetails {
    .childContainer {
      align-items: center;
    }
    > div {
      justify-content: flex-start;
    }
    .inputContainer {
      margin-bottom: $spacing-t4;
    }

    .validity {
      :global(.#{$ns}-input-group) {
        flex-basis: 50%;
      }
    }

    .addButton {
      margin: $spacing-t7 + $spacing-t6 0;
    }

    .labelContainer {
      flex-basis: 38px !important;
      max-width: 38px !important;

      label {
        color: $color-grey-080;
        font-size: $font-size-t5;
        line-height: $line-height-t5;
        font-weight: $font-weight-semi-bold;
      }
    }
  }
}

@media (max-width: 768px) {
  .scheduleWrapper {
    .scheduleDetails {
      .inputContainer {
        margin-bottom: $spacing-t7;
      }
      .labelContainer {
        flex-basis: 100% !important;
        max-width: 100% !important;
        margin-bottom: $spacing-t7;
      }
      .addButton {
        margin: 0 !important;
      }
    }
  }
}
