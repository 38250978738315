@import "./variables";
@import "./form.mixin.scss";

.formGroup {
  @include formGroup;

  .header {
    line-height: $spacing-t6 !important;
    font-size: $font-size-t3;
    font-weight: $font-weight-semi-bold;
    color: $color-text-default;
    padding-bottom: $spacing-t9;
  }
}

.container {
  .inputSelect {
    :global(.#{$ns}-button) {
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  :global(.#{$ns}-input-group) {
    :global(.#{$ns}-input) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
