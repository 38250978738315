@import "../variables";
@import "../button.mixin";

.container {
  .title {
    color: $color-text-default;
    font-size: $font-size-t5;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t5;
    margin: $spacing-t8 0;
  }

  .inputContent {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    > span {
      flex: 6;
      margin-right: $spacing-t6;
    }
    .actions {
      flex: 5;
      display: flex;
      .button {
        &.include {
          @include includeButtonWithIcon;
        }

        &.exclude {
          @include excludeButtonWithIcon;
        }
      }
    }
  }
}

.disabledButton {
  .button {
    &.include {
      @include includeButtonWithIcon;
    }

    &.exclude {
      @include excludeButtonWithIcon;
    }
  }
}
