@import "variables";
@import "form.mixin.scss";

.tabs {
  :global(.#{$ns}-tab-list) {
    border-bottom: 1px solid #e6e9ec;
    margin-bottom: 0 !important;
    overflow: auto;
    background: $color-background-white;
    padding: 0 $spacing-t4;
    box-shadow: $shadow-default;
    :global(.#{$ns}-tab[aria-selected="true"]) {
      color: $color-text-default;
    }

    :global(.#{$ns}-tab) {
      color: $color-text-secondary;
      font-size: $font-size-t4;
      font-weight: $font-weight-semi-bold;

      &:hover {
        color: $color-text-default;

        .tabCount {
          background-color: $color-button-bg-primary;
        }
      }

      &:not(:last-child) {
        margin-right: $spacing-t6;
      }
    }

    :global(.#{$ns}-tab-indicator) {
      background-color: $color-button-bg-primary;
    }
  }

  &.hidePanel {
    > div:first-child {
      display: none;
    }
  }

  &.loaderContainer {
    :global(.#{$ns}-tab-list) {
      margin-bottom: $spacing-t6 !important;
    }

    > div:nth-child(2) {
      padding: 0 $spacing-t4;
    }
  }

  :global(.#{$ns}-tab-panel) {
    margin-top: 0 !important;
  }
}

.tabName {
  margin: 0 $spacing-t8;
}

.tabCount {
  height: 18px;
  border-radius: $border-radius-medium;
  background-color: $color-border;
  color: $color-text-white;
  font-size: $font-size-t6;
  font-weight: bold;
  align-self: center;
  text-align: center;
  padding: 0.5rem;
  align-items: center;
  margin-right: 0.5rem;
}

.activeTabCount {
  @extend .tabCount;
  background-color: $color-button-bg-primary;
}

.formContainer {
  @include formContainer();
}
