@import "../variables";
@import "../form.mixin.scss";
@import "../button.mixin.scss";

.save {
  background-color: $color-button-bg-green !important;
  margin-right: $spacing-t6;
}

.actionInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-t5;
  margin-bottom: $spacing-t5;
}

.clearSelection {
  background: none !important;
  > div {
    padding: 0 !important;
  }
}

.removeButton {
  @include buttonWithIcon(transparent);
  color: $color-text-error !important;
  font-size: $font-size-t5;
  margin-top: -$spacing-t5;

  :global(.fill-color) {
    fill: $color-text-error !important;
  }
  :global(.#{$ns}-button-text) {
    color: $color-text-error !important;
    padding: 0;
  }
}

.clear {
  cursor: pointer;
}
