@import "../icon.mixin";
@import "../variables";
@import "../text-ellipsize.mixin";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    @include rotate;
    :global(.fill-color) {
      color: $color-icon-white;
      fill: $color-icon-white;
    }
  }
}

.data {
  color: $color-grey-080;
  font-size: $font-size-t4;
  line-height: $line-height-t4;
  font-weight: $font-weight-bold;
}

.clearButton {
  margin-bottom: $spacing-t8;
}

.errorList {
  margin: $spacing-t7 0;
  .errorType {
    color: $color-red-060;
    font-size: $font-size-t4;
    line-height: $line-height-t4;
    font-weight: $font-weight-semi-bold;
    margin-bottom: $spacing-t8;
  }
  .screen {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-t8;
    .name {
      font-size: $font-size-t4;
      line-height: $line-height-t4;
      color: $color-grey-080;
      @include text-ellipsize;
    }
    .location {
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      color: $color-grey-060;
      @include text-ellipsize;
    }
  }
}
