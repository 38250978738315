@import "variables";

.rangeInput {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .inputContainer {
    :global(.#{$ns}-input) {
      font-size: $font-size-t5;
      width: 150px;
      padding: $spacing-t8 0 $spacing-t8 $spacing-t7 !important;
      border: 1px solid $color-border;
      box-shadow: none;
    }
  }

  .separator {
    font-size: $font-size-t5;
    margin: 0 $spacing-t7;
    color: $color-text-default;
    text-transform: uppercase;
  }
}
