@import "./variables";
@import "./icon.mixin";

.icon {
  display: inline-flex;
  padding: 0 !important;
}
.iconContainer {
  margin-right: $spacing-t10;
}
.primary {
  :global(.fill-color) {
    color: $color-icon-primary;
    fill: $color-icon-primary;
  }
}

.green {
  :global(.fill-color) {
    color: $color-green-080;
    fill: $color-green-080;
  }
}
.lightBlue {
  :global(.fill-color) {
    color: $color-primary-080;
    fill: $color-primary-080;
  }
}
.blue {
  :global(.fill-color) {
    color: $color-primary-060;
    fill: $color-primary-060;
  }
}
.grey {
  :global(.fill-color) {
    color: $color-tags-bg-default;
    fill: $color-tags-bg-default;
  }
}

.yellow {
  :global(.fill-color) {
    color: $color-amber-080;
    fill: $color-amber-080;
  }
}

.red {
  :global(.fill-color) {
    color: $color-red-080;
    fill: $color-red-080;
  }
}

.purple {
  :global(.fill-color) {
    color: $color-primary-080;
    fill: $color-primary-080;
  }
}

.lightGrey {
  :global(.fill-color) {
    color: $color-grey-040;
    fill: $color-grey-040;
  }
}
