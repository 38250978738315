@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  } @else if $size == phone-portrait {
    @media (max-height: 320px) {
      @content;
    }
    @media (max-height: 568px) {
      @content;
    }
    @media (max-height: 640px) {
      @content;
    }
    @media (max-height: 667px) {
      @content;
    }
  } @else if $size == land-scape {
    @media (max-height: 360px) {
      @content;
    }
    @media (max-height: 411px) {
      @content;
    }
    @media (max-height: 375px) {
      @content;
    }
    @media (max-height: 414px) {
      @content;
    }
    @media (max-height: 320px) {
      @content;
    }
  }
}

@mixin no-select {
  user-select: all !important; /* Standard syntax */
}
