@import "variables";
@import "media.mixin";

.button {
  display: inline-flex;
  justify-content: center;
}

.admin,
.user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  @include for-size(phone-only) {
    height: 90vh;
    width: 100%;
  }
}

.loginMenu {
  margin-top: $spacing-t8;

  .loginMenuItem {
    align-items: center;
  }
}

.admin {
  background: $admin-gradient-background;
  .card {
    min-width: 320px;
    max-width: 520px;
    width: 40%;
    padding: $spacing-t5 * 2;
    background: white;
    text-align: center;
    box-shadow: $shadow-card;
    border-radius: $border-radius-large;
    .logo {
      margin: $spacing-t7 0 $spacing-t4 0;
      svg path {
        color: $color-icon-primary !important;
        fill: $color-icon-primary !important;
      }
    }

    .intro {
      color: $color-text-secondary;
      font-size: $font-size-t4;
      line-height: $line-height-t4;
      text-align: center;
      margin-bottom: $spacing-t3;
    }
    .button {
      align-self: center;
      min-height: $spacing-t2;
      margin-bottom: $spacing-t6;
      background: $color-primary-090;
      color: $color-text-white;
      border-radius: $border-radius-large;
      width: 310px;
      align-items: center;
    }
  }

  @include for-size(land-scape) {
    .card {
      min-width: 520px;
      max-width: 520px;
    }
  }

  @media (max-width: 768px) {
    padding: $spacing-t3;
    .card {
      min-width: 320px;
      max-width: 520px;
      padding: $spacing-t3;
      .logo {
        margin: $spacing-t7 0 $spacing-t4 0;
        svg {
          max-width: 270px;
        }
      }

      .intro {
        font-size: $font-size-t4;
        line-height: $line-height-t4;
        margin-bottom: $spacing-t3;
      }
      .button {
        padding: $spacing-t6;
        min-height: 48px;
        margin-bottom: $spacing-t6;
        max-width: 210px;
        min-width: 210px;
      }
    }
  }

  @include for-size(phone-only) {
    padding: $spacing-t4;

    .card {
      min-width: 310px;
      max-width: 310px;
      width: 80%;
      padding: $spacing-t4;
      .logo {
        max-width: 250px;
        svg {
          max-width: 250px;
        }
      }

      .intro {
        color: $color-text-secondary;
        font-size: $font-size-t4;
        line-height: $line-height-t4;
        text-align: center;
        margin-bottom: $spacing-t3;
      }
      .button {
        align-self: center;
        padding: 1rem;
        margin-bottom: $spacing-t6;
        min-height: $spacing-t2;
        max-width: 210px;
        min-width: 210px;
      }
    }
  }
}

.user {
  background: $user-gradient-background;
  flex-direction: column;
  justify-content: flex-start;
  padding: $spacing-t3 5rem 0 5rem;

  > .navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > .actions {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      button {
        color: $color-text-primary;
      }
    }
  }

  > .page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 2;

    > .description {
      align-self: flex-start;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      max-width: 50%;
      margin-top: $spacing-t3 * 5;

      > .title {
        color: #ffffff;
        font-size: 48px;
        line-height: 65px;
        margin-bottom: $spacing-t6;
        text-align: left;
      }

      > p {
        color: #ffffff;
        font-size: $font-size-t4;
        line-height: $line-height-t2;
      }
    }

    > .placeholder {
      max-width: 100%;
      > svg {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    padding: $spacing-t3;
    > .navBar {
      align-items: center;
      justify-content: space-between;
      > .logo {
        margin-bottom: 0;
      }
    }

    > .page {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: $spacing-t4;

      > .description {
        max-width: 100%;
        > .title {
          width: 100%;
          text-align: left;
        }
      }

      > .placeholder {
        margin-top: $spacing-t4;
        width: 100%;
        > svg {
          height: 50vh;
        }
      }
    }
  }

  @include for-size(phone-only) {
    padding: $spacing-t4;
    > .navBar {
      flex-direction: column;
      align-items: flex-start;
      > .logo {
        margin-bottom: $spacing-t4;
      }
    }

    > .page {
      flex-direction: column;
      align-items: flex-start;
      margin-top: $spacing-t6;

      > .description {
        max-width: 100%;
        margin-top: $spacing-t3;
        > .title {
          color: #ffffff;
          font-size: 36px;
          line-height: 50px;
          margin-bottom: $spacing-t6;
          text-align: left;
        }

        > p {
          color: #ffffff;
          font-size: 16px;
          line-height: 30px;
        }
      }

      > .placeholder {
        > svg {
          max-width: 90vw;
        }
      }
    }
  }
}
