@import "variables";

.dimensionInput {
  display: flex;
  align-items: center;

  > .separator {
    align-self: flex-end !important;
    margin: 0 $spacing-t7 $spacing-t8 $spacing-t7;
    font-size: $font-size-t5;
    color: $color-text-default;
  }

  .rangeWrapper {
    display: flex;
    align-items: center;

    > .separator {
      align-self: flex-end !important;
      margin: 0 $spacing-t9 $spacing-t9 $spacing-t9;
    }
  }

  .rangeWrapper .inputContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      color: $color-text-default;
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t5;
      margin: $spacing-t8 0;
    }

    :global(.#{$ns}-input) {
      font-size: $font-size-t5;
      width: 50px;
      padding: $spacing-t8 0 $spacing-t8 $spacing-t8 !important;
      border: 1px solid $color-border;
      box-shadow: none;
    }
  }
}
