@import "./variables";
@import "./button.mixin.scss";

.dropdownMenu {
  box-shadow: $shadow-card;
  border-radius: $border-radius-small;
  padding: 0;

  .dropdownMenuItem {
    color: $color-text-default !important;
    font-size: $font-size-t5 !important;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $color-primary-020;
    }

    :global(.#{$ns}-icon) {
      color: $color-icon-primary !important;
    }
  }
}

.popoverContainer {
  display: flex;
  justify-content: center;
}

.popoverButton {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: $border-radius-small;

  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
  }
}

:global(.fill-color) {
  fill: $color-icon-primary;
}

:global(.#{$ns}-popover) {
  box-shadow: none;
}

:global(.#{$ns}-menu-item) {
  padding: $spacing-t7 $spacing-t7;
  font-weight: $font-weight-semi-bold;
}
