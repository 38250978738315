@import "variables";
@import "form.mixin.scss";

.tableControls {
  background: $color-white;
  padding: $spacing-t6;
  border-bottom: 1px solid $color-border;
  border-top-right-radius: $border-radius-large;
  border-top-left-radius: $border-radius-large;
}

.placeholder {
  height: 48px;
  width: 328px;
  border-style: dotted;
  border-color: $color-grey-060;
  &:before {
    display: flex;
    justify-content: center;
    padding: $spacing-t7;
    content: "Drop here";
    color: $color-text-secondary;
  }
}
.expandContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 2 1;

  .secondaryControls {
    padding: 0 $spacing-t6 0 0;
  }
}

.settingIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $color-grey-020 !important;
  padding: $spacing-t9 $spacing-t8;
  min-height: $spacing-t3 !important;
  border-radius: $spacing-t9 !important;
  :global(.fill-color) {
    fill: $color-icon-default;
  }
}

.controlContainer {
  align-items: center;
  padding: 0 !important;

  .controls {
    margin: 0 $spacing-t6 0 0;
    display: flex;

    &:last-child {
      margin: 0;
    }
  }
}

.searchContainer {
  align-items: center !important;
  padding: 0 $spacing-t6 0 0;
}

.searchBar {
  width: auto;

  :global(.#{$ns}-input-group) {
    width: 15rem !important;
    transition: width 0.4s;

    input {
      @include inputStyle();
    }
  }

  &.focusedSearchInput {
    :global(.#{$ns}-input-group) {
      width: 40rem !important;

      @media only screen and (min-width: 768px) and (max-width: 900px) {
        width: 35rem !important;
      }
    }
  }
}

.filter {
  margin-left: $spacing-t6;
}

//Will move it to separate file later.
//Media quries for Responsive view.
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .container {
    flex-direction: column;
    align-items: flex-start;

    .expandContainer {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: $spacing-t7;
      width: 100%;
    }
  }

  .searchContainer {
    width: 100% !important;
  }

  .searchBar {
    width: 100%;

    :global(.#{$ns}-input-group) {
      width: 100% !important;
      min-width: 15rem !important;
      transition: none;

      input {
        @include inputStyle();
      }
    }

    &.focusedSearchInput {
      width: 90% !important;

      :global(.#{$ns}-input-group) {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 320px) {
  .controlContainer {
    .controls {
      margin: 0 $spacing-t9 0 0;
    }
  }
}
