@import "variables";
@import "form.mixin.scss";
@import "label.mixin.scss";

.multiSelect {
  .label {
    @include label;
  }

  .inputValue {
    position: relative;
    :global(.#{$ns}-tag-input) {
      border-radius: $border-radius-medium !important;
      border: 1px solid $color-border;
      max-height: 105px !important;
      overflow: scroll;
      font-size: $font-size-t5 !important;
      box-shadow: none;

      :global(.#{$ns}-tag-input-values) {
        margin-top: $spacing-t9;
      }
    }

    :global(.#{$ns}-transition-container),
    :global(.#{$ns}-multi-select-popover) {
      width: 100% !important;
    }
    :global(.#{$ns}-active) {
      box-shadow: none !important;
    }
    :global(.#{$ns}-popover-content) {
      box-shadow: $shadow-card !important;
      :global(.#{$ns}-menu) {
        max-width: none !important;
        width: 100% !important;
      }
      :global(.#{$ns}-text-overflow-ellipsis) {
        white-space: inherit;
      }
    }
    :global(.fill-color) {
      color: $color-icon-secondary;
      fill: $color-icon-secondary;
    }
  }
}
