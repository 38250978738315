@import "../variables";
@import "../text-ellipsize.mixin";

.addException {
  align-self: flex-start;
}

.clearButton {
  background: none !important;
  > div {
    padding: 0 !important;
  }
}

.allDaysButton {
  width: 100%;
  border-radius: $border-radius-medium;
  border: 1px solid $color-grey-020;
  background: $color-grey-010 !important;
  margin-right: 0 !important;

  &:hover {
    background: $color-grey-010 !important;
  }
}

.errorWrapper {
  flex-direction: column;
  > div {
    font-size: $font-size-t5;
    line-height: $line-height-t4;
    color: $color-text-error;
  }
}

.container {
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $spacing-t6;

  .formWrapper {
    align-items: center;
    justify-content: flex-start;
    margin-bottom: $spacing-t9;

    .deleteIcon {
      margin-left: $spacing-t8 !important;
      display: flex;
      align-items: center;
      cursor: pointer;
      :global(.fill-color) {
        color: $color-red-080;
        fill: $color-red-080;
      }
    }

    > .inputWrapper {
      margin-left: $spacing-t7;
      padding: 0 $spacing-t9 !important;
      > div {
        margin-bottom: 0;
      }
    }

    > .contentInputWrapper {
      flex: 4;
      align-items: center;
      max-width: 140px;

      > div {
        margin-bottom: 0;
      }

      > span {
        flex: 2;
        padding: 0 $spacing-t8 !important;
        color: #1c283f;
        font-size: $font-size-t5;
        line-height: $line-height-t4;
        margin-left: $spacing-t9;
      }

      .emptyExceptionIcon {
        width: 24px;
        height: 24px;
      }

      .emptyExceptionIcon,
      .exceptionIcon {
        display: flex;
        align-items: center;
        margin-right: $spacing-t10;
        :global(.fill-color) {
          color: $color-icon-default;
          fill: $color-icon-default;
        }
      }
    }

    > .contentInputWrapper .contentInput {
      padding-right: $spacing-t8 !important;
      button {
        border-radius: $border-radius-large !important;
        border: 1px solid $color-border;
        font-size: $font-size-t5 !important;
        line-height: $line-height-t4;
        box-shadow: none;
        color: $color-text-default !important;
        max-width: 100px !important;
        min-width: 100px !important;
        padding: 0 !important;
        > div {
          div:nth-child(1) {
            display: inline-block;
            flex: 1;
            @include text-ellipsize;
          }
          div:nth-child(2) {
            margin: 0;
          }
        }
      }
    }
  }
}
