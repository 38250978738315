@import "variables";
@import "icon.mixin";
@import "form.mixin";
@import "label.mixin.scss";

.block {
  margin-bottom: $spacing-t3;
  border-radius: $border-radius-large;

  &.primary {
    box-shadow: $shadow-card;
    background-color: $color-background-white;
  }

  &.secondary {
    background-color: $color-surface !important;
    box-shadow: none !important;
  }

  &.inline {
    background-color: $color-background-white !important;
    box-shadow: none !important;
  }
}

.cardContainer {
  padding: $spacing-t7;
  > * {
    align-self: flex-start;
    padding: $spacing-t7;

    &.cardList {
      padding: 0 !important;

      > * {
        padding: $spacing-t7;
      }
    }

    &:empty {
      display: none;
    }
  }
}

.label {
  color: $color-text-default;
  font-size: $font-size-t5 !important;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-t5;
  text-transform: capitalize;
}

.textValue {
  color: $color-text-default;
  font-size: $font-size-t4 !important;
  line-height: $line-height-t4;
  overflow: auto;
  text-overflow: ellipsis;
}

.tableBlock {
  margin-bottom: $spacing-t3;
}

.tableContainer {
  padding: $spacing-t6 $spacing-t4;
  padding-top: 0;
  background: $color-background-white;
}

.wrapper {
  padding-right: $spacing-t8;
}

.headerWrapper {
  display: flex;
  align-items: center;
  padding: $spacing-t7 $spacing-t4;
  border-bottom: 1px solid $color-border;

  .header {
    display: flex;
    align-items: center;
    white-space: nowrap;
    .headerTitle {
      @include headerText;
      padding: $spacing-t9 0;
    }

    .headerIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      color: $color-text-primary;
      border: 1px solid $color-icon-primary;
      border-radius: $border-radius-large;
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      min-width: 32px;
      padding: $spacing-t9;
      margin: 0 $spacing-t7 0 0;

      :global(.fill-color) {
        color: $color-icon-primary !important;
        fill: $color-icon-primary !important;
      }
    }

    &.subHeader {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-t10;

      .headerTitle {
        @include headerText;
        font-size: $font-size-t4 !important;
        line-height: $line-height-t4;
      }
    }
  }

  .toolTip {
    margin: 0 !important;
  }
}

.contactCardList {
  background-color: $color-background-white;
  padding: $spacing-t7 !important;
  .contactContainer {
    border-radius: $border-radius-large;
    background-color: $color-surface;
    padding: $spacing-t6;

    > .title {
      color: $color-text-default;
      font-size: $font-size-t4;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t4;
    }

    > .subTitle {
      color: $color-text-secondary;
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t5;
      margin-top: $spacing-t9;
    }

    > .contactInfo {
      display: flex;
      align-items: center;
      margin-top: $spacing-t8;

      :global(.fill-color) {
        fill: $color-icon-secondary !important;
      }

      span {
        color: $color-text-default;
        padding: 0 $spacing-t9;
        font-size: $font-size-t5;
        font-weight: $font-weight-regular;
        line-height: $line-height-t5;
      }
    }
  }
}

.inputTitle {
  display: flex;
  flex-direction: row;
  line-height: $spacing-t5 !important;
  font-size: $font-size-t5 !important;
  font-weight: $font-weight-semi-bold !important;
  color: $color-text-secondary !important;
  // padding-bottom: $spacing-t9 !important;
}
