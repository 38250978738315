@import "variables";

$wb-font-family: $font-family;
$wb-font-weight-semi-bold: $font-weight-semi-bold;
$wb-primary-text-color: $color-text-default;
$wb-heading-text-color: $color-text-default;
$wb-highlight-background-color: $color-tables-zebra-light;
$wb-highlight-text-color: $color-text-default;
$wb-checkbox-color: $color-icon-secondary;
$wb-es-heading-color: $color-text-default;
$wb-es-body-color: $color-text-secondary;
$wb-es-background-color: $color-background-white;
$wb-sorted-icon-color: $color-icon-default;
$wb-unsorted-icon-color: $color-icon-secondary;
$wb-pagination-item-text-color: $color-text-default;
$wb-pagination-item-active-text-color: $color-text-default;
$wb-pagination-item-highlight-color: $color-button-bg-primary-3;
$wb-expanded-child-background-color: $color-tables-zebra-dark;
$wb-expand-icon-disabled-color: $color-icon-disabled;
$wb-resizer-icon-color: $color-grey-040;
$wb-checkbox-order: 1;
$wb-pagination-padding: $spacing-t9 $spacing-t8;
$wb-pagination-next-prev-padding: $spacing-t9 $spacing-t8;
$wb-cell-line-height: $line-height-t3;
$wb-border-color: $color-grey-020;
$wb-header-border-color: $color-border;
$wb-row-border-width: 1px 0px 0px 0px;
$wb-table-outer-border-width: 0px;
